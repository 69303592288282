import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';

import { View } from 'src/components/bappo-components/src';
import { lazyWithRetry } from 'src/utils/code-splitting';

import { AuthenticatedRequester } from './apis/AuthenticatedRequester';
import { ModelProvider } from './apis/ModalProvider';
import { ApiClient } from './apis/rpc/ApiClient';
import Page from './components/Page';
import { PopupManager } from './components/PopupManager';
import HomePage from './containers/HomePage';
import LoginPage from './containers/LoginPage';
import ResetPasswordPage from './containers/ResetPasswordPage';

const SideNavigationBar = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SideNavigationBar" */ './components/SideNavigationBar'
    ),
);
const MasterDataRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MasterDataRouter" */ './masterdata/MasterDataRouter'
    ),
);
const CustomerBillingPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerBillingPage" */ './containers/CustomerBillingPage'
    ),
);
const UserSettingsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "UserSettingsPage" */ './containers/UserSettingsPage'
    ),
);
const CartageRateFinderPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CartageRateFinderPage" */ './containers/CartageRateFinderPage'
    ),
);
const OnshoreServiceRateFinderPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "OnshoreServiceRateFinderPage" */ './containers/OnshoreServiceRateFinderPage'
    ),
);
const FreightRateFinderPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "FreightRateFinder" */ './containers/FreightRateFinderPage'
    ),
);
const FreightCharges = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "RateManager" */ './containers/FreightCharges'),
);
const Help = lazyWithRetry(
  () => import(/* webpackChunkName: "Help" */ './containers/HelpPage'),
);
const PortCharges = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "PortCharges" */ './containers/PortCharges'),
);
const RateFinder = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "RateFinder" */ './containers/RateFinderPage'),
);
const RateMaintenance = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "RateMaintenance" */ './containers/RateMaintenance'
    ),
);
const StandardCharges = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "StandardCharges" */ './containers/StandardCharges'
    ),
);
const TenderListPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TenderListPage" */ './containers/TenderListPage'
    ),
);
const TenderBuilder = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TenderBuilder" */ './components/tender-builder'
    ),
);
const TransportCharges = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TransportCharges" */ './containers/TransportCharges'
    ),
);
const TenderCountDashboard = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TenderCountDashboard" */ './containers/TenderCountDashboard'
    ),
);
const CostingCalculator = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CostingCalculator" */ './components/costing-calculator'
    ),
);
const UserListPage = lazyWithRetry(
  () => import(/* webpackChunkName: "UserList" */ './masterdata/UserListPage'),
);
const UserDetailsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "UserDetails" */ './masterdata/UserDetailsPage'
    ),
);
const ApiKeysPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ApiKeys" */ './containers/ApiKeysPage'),
);
const ChangesView = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ChangesView" */ './components/changes-view'
    ),
);
const AirTerminalCharges = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "AirTerminalCharges" */ './containers/AirTerminalCharges'),
);
const OnshoreServiceCharges = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "OnshoreServices" */ './containers/OnshoreServices'),
);

/**
 * Routes for Master data screens (List page, details page)
 */
function RateMaintenanceRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={RateMaintenance} />
      <Route path={`${path}/freight-charges`} component={FreightCharges} />
      <Route path={`${path}/transport-charges`} component={TransportCharges} />
      <Route path={`${path}/standard-charges`} component={StandardCharges} />
      <Route path={`${path}/port-charges`} component={PortCharges} />
      <Route path={`${path}/air-terminal-charges`} component={AirTerminalCharges} />
      <Route path={`${path}/onshore-service-charges`} component={OnshoreServiceCharges} />
    </Switch>
  );
}

export default function AppRouter() {
  return (
    <Router>
      <Container>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/account/forgot-password" component={ResetPasswordPage} />
          <AuthenticatedRequester>
            <ApiClient>
              <ModelProvider>
                <PopupManager>
                  {(popupManager) => {
                    return (
                      <>
                        <SideNavigationBar menuItems={menuItems} />
                        <Page>
                          <Switch>
                            <Route path="/help" component={Help} />
                            <Route
                              path="/dashboard"
                              component={TenderCountDashboard}
                            />
                            <Route path="/rate-maintenance">
                              <RateMaintenanceRouter />
                            </Route>
                            <Route path="/rate-finder" component={RateFinder} />
                            <Route
                              path="/freight-rate-finder"
                              component={FreightRateFinderPage}
                            />
                            <Route
                              path="/cartage-rate-finder"
                              component={CartageRateFinderPage}
                            />
                            <Route
                              path="/onshore-service-rate-finder"
                              component={OnshoreServiceRateFinderPage}
                            />
                            <Route
                              path="/quote-list"
                              component={TenderListPage}
                            />
                            <Route
                              path="/quote-builder"
                              component={TenderBuilder}
                            />
                            <Route
                              path="/costing-calculator"
                              component={CostingCalculator}
                            />
                            <Route
                              exact
                              path="/users"
                              component={UserListPage}
                            />
                            <Route
                              path="/users/:recordId"
                              component={UserDetailsPage}
                            />
                            <Route path="/master-data">
                              <MasterDataRouter />
                            </Route>
                            <Route path="/api-keys">
                              <ApiKeysPage />
                            </Route>
                            <Route
                              path="/publish-history-details/:recordId"
                              component={ChangesView}
                            />
                            <Route
                              path="/billing"
                              component={CustomerBillingPage}
                            />
                            <Route
                              path="/user-settings"
                              component={UserSettingsPage}
                            />
                            <Route path="/" component={HomePage} />
                          </Switch>
                          {popupManager.elements}
                        </Page>
                      </>
                    );
                  }}
                </PopupManager>
              </ModelProvider>
            </ApiClient>
          </AuthenticatedRequester>
        </Switch>
      </Container>
    </Router>
  );
}

const menuItems = [
  {
    destination: '/',
    icon: 'home',
    label: 'Home',
  },
  {
    destination: '/quote-list',
    icon: 'collections-bookmark',
    label: 'Quote Builder',
  },
  {
    destination: '/freight-rate-finder',
    icon: 'directions-boat',
    label: 'Freight Rate Finder',
  },
  {
    destination: '/cartage-rate-finder',
    icon: 'local-shipping',
    label: 'Cartage Rate Finder',
  },
  {
    destination: '/onshore-service-rate-finder',
    icon: 'note-add',
    label: 'Onshore Service Rate Finder',
  },
  {
    destination: '/rate-maintenance',
    icon: 'folder',
    label: 'Rate Maintenance',
  },
  {
    destination: '/master-data',
    icon: 'folder',
    label: 'Master Data',
  },
  {
    destination: '/help',
    icon: 'help',
    label: 'Help',
  },
  {
    destination: '/dashboard',
    icon: 'table-chart',
    label: 'Dashboard',
  },
  {
    destination: '/users',
    icon: 'people',
    label: 'Users',
  },
  {
    destination: '/api-keys',
    icon: 'vpn-key',
    label: 'API Keys',
  },
];

const Container = styled(View)`
  flex: 1;
  flex-direction: row;
  overflow: hidden;
`;
