import React, { createContext, useContext } from 'react';

import { objInfoMap } from 'src/app-env';

import { useApiClient } from '../rpc/ApiClient';
import ModelManager from './ModelManager';

const ModelContext = createContext<ModelManager | undefined>(undefined);

export function ModelProvider({ children }: { children?: React.ReactNode }) {
  const apiClient = useApiClient();
  const modelManager = new ModelManager({
    apiClient,
    objInfoMap,
  });

  return (
    <ModelContext.Provider value={modelManager}>
      {children}
    </ModelContext.Provider>
  );
}

export function useModelMap() {
  const modelManager = useContext(ModelContext)!;
  return modelManager.getUserDefinedObjectModelMap();
}

export function useModel(objectKey: string) {
  const map = useModelMap();
  return map[objectKey];
}
