import styled from 'styled-components';

import background from 'src/assets/images/background_image.jpg';
import Link from 'src/components/Link';
import media from 'src/style/media';

export const Container = styled.div`
  flex: 1;
  display: flex;
  min-height: 0;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  padding: 0 64px;
  width: 434px;
  height: 100%;
  justify-content: center;
  overflow: auto;
  background-color: rgb(31, 40, 51);

  ${media.tablet`
    width: 100%;
  `};
`;

export const ImageContainer = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 109px 78px;
  position: relative;
  flex: 1;

  ${media.tablet`
    display: none;
  `};
`;

export const TextContainer = styled.div`
  min-height: 0;
`;

export const PrimaryText = styled.div`
  font-size: 56px;
  font-weight: bold;
  color: #1f2833;
  line-height: 1;
  margin-left: 40%;
  width: 400px;
`;

export const SecondaryText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.navlab.gray94};
  margin-top: 20px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const LinkGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 13px;
  min-height: 0;
`;

export const Divider = styled.div`
  margin: 0 13px;

  &::after {
    content: '|';
  }
`;

export const ForgotPasswordLink = styled(Link)`
  font-size: 14px;
  color: silver;

  &:hover {
    opacity: 0.7;
  }
`;
