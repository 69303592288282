import React from 'react';

import { DatePicker, Icon } from 'src/components/bappo-components/src';
import { DatePickerProps } from 'src/components/bappo-components/src/components/DatePicker/types';
import { DateField, ObjectFieldTypes } from 'src/types/object';

import { createField } from './createField';

export type DateInputProps = Omit<
  DatePickerProps,
  'displayFormat' | 'renderDropdownIcon'
> & {
  properties: DateField['properties'];
};

const renderDropdownIcon = () => <Icon name="calendar-today" />;

export const DateInput = React.forwardRef(
  ({ properties, ...rest }: DateInputProps, ref: React.Ref<DatePicker>) => {
    const { format } = properties;

    return (
      <DatePicker
        {...rest}
        displayFormat={format || 'DD/MM/YYYY'}
        renderDropdownIcon={renderDropdownIcon}
        ref={ref}
        modal
      />
    );
  },
);

export const DateInputField = createField(ObjectFieldTypes.DATE, DateInput);
