import React from 'react';
import stringify from 'json-stable-stringify';
import {
  getResultFromCacheAccordingToAttributesAndInclude,
  recordCacheIdType,
} from './helpers';
import { ApiClientContext } from './ApiClient';

const getData = ({ objectKey, ids, attributes, include, apiClient }) => {
  if (!objectKey || !ids) return [];
  const recordCacheIds = ids.map(id => `${objectKey}:${id}`);
  return getResultFromCacheAccordingToAttributesAndInclude(
    recordCacheIds.map(id => ({
      type: recordCacheIdType,
      id,
    })),
    apiClient.cache.denormalizeRecord.bind(apiClient.cache),
    {
      options: {
        attributes,
        include,
      },
    },
  ).filter(Boolean);
};

export default function useCachedRecords({
  objectKey,
  ids,
  attributes,
  include,
}) {
  const apiClient = React.useContext(ApiClientContext);

  const [data, setData] = React.useState(
    getData({
      objectKey,
      ids,
      attributes,
      include,
      apiClient,
    }),
  );

  // watch cached records
  React.useEffect(
    () => {
      // reset state
      setData(
        getData({
          objectKey,
          ids,
          attributes,
          include,
          apiClient,
        }),
      );

      let unwatch;
      if (objectKey && ids) {
        unwatch = apiClient.watchResult({
          normalizedResult: ids.map(id => ({
            type: recordCacheIdType,
            id: `${objectKey}:${id}`,
          })),
          callback: ({ type, payload }) => {
            switch (type) {
              case 'cache:update': {
                setData(
                  getData({
                    objectKey,
                    ids,
                    attributes,
                    include,
                    apiClient,
                  }),
                );
                break;
              }
              case 'cache:invalidate':
                setData([]);
                break;
              default:
                break;
            }
          },
        });
      }

      return () => {
        unwatch && unwatch();
      };
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      apiClient,
      stringify(attributes),
      stringify(ids),
      stringify(include),
      objectKey,
    ],
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  return data;
}
