import _ from 'lodash';

import {
  bulkCreate,
  bulkUpdate,
  create,
  destroy,
  forceDestroy,
  update,
  updateOne,
} from '../rpc/queries/common-mutations';
import {
  findAll,
  findAndCountAll,
  findOne,
} from '../rpc/queries/common-queries';
import { getDefaultAttributes } from '../rpc/utils';

export function createUserDefinedObjectBaseModel({ apiClient }) {
  /**
   * Abstract class that all object models extend from
   */
  return class Model {
    /**
     * Create multiple records
     *
     * @param {Array<Object>} records
     * @returns {Promise<Array<ObjectInstance>>} an array of new records
     *
     * @example
     * $models.Contact.bulkCreate([
     *   { name: 'Alice' },
     *   { name: 'Bob' },
     *   { name: 'Charles' },
     * ]);
     */
    static async bulkCreate(records) {
      const newRecords = await apiClient.mutate({
        method: bulkCreate(this.definition.key),
        params: {
          records,
        },
      });
      return newRecords;
    }

    /**
     * Update multiple records
     *
     * @param {Array<Object>} an array of record updates
     *
     * @example
     * $models.Contact.bulkUpdate([
     *   { id: '1', name: 'Alice' },
     *   { id: '2', name: 'Bob' },
     * ]);
     */
    static async bulkUpdate(records) {
      records.forEach((record, index) => {
        if (!record || !record.id) {
          throw new Error(`Missing "id" property at index ${index}`);
        }
      });
      const result = await apiClient.mutate({
        method: bulkUpdate(this.definition.key),
        params: {
          objectKey: this.definition.key,
          records,
        },
      });
      return result;
    }

    /**
     * Creates a record
     *
     * @param {Object} values
     * @returns {Promise<ObjectInstance>} the new record
     *
     * @example
     * $models.Contact.create({
     *   name: 'John',
     *   customer_id: '1', // reference field `customer`
     * });
     */
    static async create(values) {
      const result = apiClient.mutate({
        method: create(this.definition.key),
        params: {
          values,
        },
      });
      return result;
    }

    /**
     * Delete multiple records
     *
     * @param {Object} options
     * @param {Object} options.where - Filter the destroy
     * @returns {Promise<number>} the number of deleted records
     *
     * @example
     * $models.Contact.destroy({
     *   where: {
     *     name: 'Alice',
     *   },
     * });
     */
    static async destroy({ where } = {}) {
      const result = await apiClient.mutate({
        method: destroy(this.definition.key),
        params: {
          options: {
            where,
          },
        },
      });
      return result;
    }

    /**
     * Permanently delete multiple records
     *
     * @param {Object} options
     * @param {Object} options.where - Filter the forceDestroy
     * @returns {Promise<number>} the number of deleted records
     *
     * @example
     * $models.Contact.forceDestroy({
     *   where: {
     *     name: 'Alice',
     *   },
     * });
     */
     static async forceDestroy({ where } = {}) {
      const result = await apiClient.mutate({
        method: forceDestroy(this.definition.key),
        params: {
          options: {
            where,
          },
        },
      });
      return result;
    }

    /**
     * Deletes a record
     *
     * @param {String} recordId
     * @returns {Promise<>}
     *
     * @example
     * $models.Contact.destroyById('1');
     */
    static async destroyById(recordId) {
      const result = await apiClient.mutate({
        method: destroy(this.definition.key),
        params: {
          options: {
            where: {
              id: {
                $eq: recordId,
              },
            },
          },
        },
      });
      return result;
    }

    /**
     * Retrieves records
     *
     * @param {Object} options - allow `include`, `where`, `offset` and `limit`
     * @returns {Promise<Array<ObjectInstance>>} an array of records
     *
     * @example
     * $models.Customer.findAll({
     *   include: [ // Same as sequelize except you don't need to specify `model`
     *     {
     *       as: 'contacts', // target name of the relationship
     *     },
     *   ],
     *   where: { // See sequelize docs
     *     name: 'Coles',
     *   },
     *   offset: 100,
     *   limit: 50,
     * });
     */
    static async findAll({
      attributes,
      include,
      where,
      offset,
      limit,
      order,
    } = {}) {
      const records = await apiClient.query({
        method: findAll(this.definition.key),
        params: {
          options: {
            attributes: attributes || getDefaultAttributes(this.fields),
            include,
            where,
            offset,
            limit,
            order,
          },
        },
      });
      return records;
    }

    /**
     * Retrieves records and the total number
     *
     * @param {Object} queryOpts - allow `include`, `where`, `offset` and `limit`
     * @returns {Object}
     *
     * @example
     * $models.Customer.findAndCountAll({
     *   include: [ // Same as sequelize except you don't need to specify `model`
     *     {
     *       as: 'contacts', // target name of the relationship
     *     },
     *   ],
     *   where: { // See sequelize docs
     *     name: 'Coles',
     *   },
     *   offset: 100,
     *   limit: 50,
     * })
     * .then((res) => {
     *   console.log(res.records); // Array of records
     *   console.log(res.total);   // Total number of records in the database
     * });
     */
    static async findAndCountAll({
      attributes,
      include,
      where,
      offset,
      limit,
      order,
    } = {}) {
      const { count, rows } = await apiClient.query({
        method: findAndCountAll(this.definition.key),
        params: {
          options: {
            attributes: attributes || getDefaultAttributes(this.fields),
            include,
            where,
            offset,
            limit,
            order,
          },
        },
      });
      return {
        records: rows,
        total: count,
      };
    }

    /**
     * Retrieve a record by id
     *
     * @param {String} recordId
     * @param {Object} options - allow `include`
     * @returns {Promise<ObjectInstance>} the updated record
     *
     * @example
     * $models.Customer.findById('1', {
     *   include: [ // Same as sequelize except you don't need to specify `model`
     *     {
     *       as: 'contacts', // target name of the relationship
     *     },
     *   ],
     * });
     */
    static async findById(recordId, { attributes, include } = {}) {
      if (!_.isString(recordId) && !isFinite(recordId)) {
        throw new Error('Argument passed to findById is invalid', recordId);
      }
      const record = await apiClient.query({
        method: findOne(this.definition.key),
        params: {
          options: {
            attributes: attributes || getDefaultAttributes(this.fields),
            include,
            where: {
              id: {
                $eq: recordId,
              },
            },
          },
        },
      });
      return record;
    }

    /**
     * Retrieve a record
     *
     * @param {Object} queryOpts - allow `include` and `where`
     * @returns {Promise<ObjectInstance>}
     *
     * @example
     * $models.Customer.findOne({
     *   include: [ // Same as sequelize except you don't need to specify `model`
     *     {
     *       as: 'contacts', // target name of the relationship
     *     },
     *   ],
     *   where: { // See sequelize docs
     *     name: 'Coles',
     *   },
     * });
     */
    static async findOne({ attributes, include, where } = {}) {
      const record = await apiClient.query({
        method: findOne(this.definition.key),
        params: {
          options: {
            attributes: attributes || getDefaultAttributes(this.fields),
            include,
            where,
          },
        },
      });
      return record;
    }

    /**
     * Update multiple records
     *
     * @param {Object} values
     * @param {Object} options
     * @param {Object} options.where - Options to describe the scope of the search
     * @returns {Promise<Array<ObjectInstance>> | null} Return the updated record if only affected one record, or empty if affected more than one
     *
     * @example
     * $models.Contact.update({
     *   name: 'Bob',
     * }, {
     *   where: {
     *     name: 'Alice',
     *   },
     * });
     */
    static async update(values, { where } = {}) {
      const result = await apiClient.mutate({
        method: update(this.definition.key),
        params: {
          values,
          options: {
            where,
          },
        },
      });
      return result;
    }

    /**
     * Update one record
     *
     * @param {Object} values
     * @returns {Promise<ObjectInstance>} Return the updated record
     *
     * @example
     * $models.Contact.updateOne({
     *   name: 'Bob',
     * }, {
     *   where: {
     *     id: '123',
     *   },
     * });
     */
    static async updateOne(values, options) {
      if (
        !(
          options &&
          options.where &&
          typeof options.where === 'object' &&
          Object.keys(options.where).length > 0
        )
      ) {
        throw new Error(
          'Invalid params. The first param should be the updated values and second param should be options including query in "where"',
        );
      }

      const result = await apiClient.mutate({
        method: updateOne(this.definition.key),
        params: {
          values,
          options: {
            where: options.where,
          },
        },
      });
      return result;
    }
  };
}
