import React from 'react';

import OldNumberInput from 'src/components/BaseComponents/NumberInput';
import {
  NumberInput,
  NumberInputProps,
} from 'src/components/BaseComponents/NumberInput/NumberInput';
import { TextInputHandle } from 'src/components/bappo-components/src/primitives/TextInput/types';
import { ObjectFieldTypes, QuantityField } from 'src/types/object';

import { createField } from './createField';

export type QuantityInputProps = Omit<
  NumberInputProps,
  'multiline' | 'type'
> & {
  properties: QuantityField['properties'];
};

export const QuantityInput = React.forwardRef(function QuantityInput(
  { properties, ...rest }: Omit<QuantityInputProps, 'ref'>,
  ref: React.Ref<TextInputHandle>,
) {
  const { decimalPlaces } = properties;

  return <NumberInput {...rest} ref={ref} decimalPlaces={decimalPlaces} />;
});

export const OldQuantityInput = React.forwardRef(function QuantityInput(
  { properties, ...rest }: Omit<QuantityInputProps, 'ref'>,
  ref: React.Ref<TextInputHandle>,
) {
  const { decimalPlaces, unit, notShowUnit } = properties;

  return (
    <OldNumberInput
      {...rest}
      decimalPlaces={decimalPlaces}
      unit={notShowUnit ? undefined : unit}
      inputRef={ref}
    />
  );
});

export const QuantityInputField = createField(
  ObjectFieldTypes.QUANTITY,
  QuantityInput,
);
