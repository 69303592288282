import React from 'react';

import { Icon, Select } from 'src/components/bappo-components/src';
import { SelectProps } from 'src/components/bappo-components/src/components/OldSelect/types';
import { MonthField, ObjectFieldTypes } from 'src/types/object';
import { monthOptions } from 'src/utils/FieldUtils';

import { createField } from './createField';

const renderDropdownIcon = () => <Icon name="calendar-today" />;

export type MonthInputProps = Pick<
  SelectProps,
  | 'accessibilityLabel'
  | 'autoFocus'
  | 'clearable'
  | 'onBlur'
  | 'onFocus'
  | 'onValueChange'
  | 'placeholder'
  | 'readOnly'
  | 'style'
  | 'testID'
  | 'value'
> & {
  properties: MonthField['properties'];
};

export const MonthInput = React.forwardRef(function MonthInput(
  { properties, value, ...rest }: MonthInputProps,
  ref: React.Ref<{
    blur: () => void;
    focus: () => void;
  }>,
) {
  return (
    <Select
      {...rest}
      options={monthOptions}
      renderDropdownIcon={renderDropdownIcon}
      ref={ref}
      value={Number(value)}
    />
  );
});

export const MonthInputField = createField(ObjectFieldTypes.MONTH, MonthInput);
