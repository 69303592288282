import React from 'react';

import { TextInput } from 'src/components/bappo-components/src';
import { TextInputProps } from 'src/components/bappo-components/src/primitives/TextInput/types';
import { AutoIncrementField, ObjectFieldTypes } from 'src/types/object';

import { createField } from './createField';

export type AutoIncrementInputProps = Omit<
  TextInputProps,
  'multiline' | 'type'
> & {
  properties: AutoIncrementField['properties'];
};

export const AutoIncrementInput = React.forwardRef(function AutoIncrementInput(
  { properties, value, readOnly, ...rest }: AutoIncrementInputProps,
  ref: React.Ref<TextInput>,
) {
  return (
    <TextInput
      {...rest}
      multiline={false}
      readOnly={readOnly ?? true}
      type="text"
      value={value == null ? 'Automatically Generated' : value}
      ref={ref}
    />
  );
});

export const AutoIncrementInputField = createField(
  ObjectFieldTypes.AUTOINCREMENT,
  AutoIncrementInput,
);
