import { required, isEmail } from 'src/utils/ValidationUtils';

export const emailValidators = [
  required({
    msg: 'Email is required',
  }),
  isEmail(),
];

export const passwordValidators = [
  required({
    msg: 'Password is required',
  }),
];

export const nameValidators = [required()];
