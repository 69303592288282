import React from 'react';

import OldNumberInput from 'src/components/BaseComponents/NumberInput';
import {
  NumberInput,
  NumberInputProps,
} from 'src/components/BaseComponents/NumberInput/NumberInput';
import { TextInputHandle } from 'src/components/bappo-components/src/primitives/TextInput/types';
import { ObjectFieldTypes, YearField } from 'src/types/object';

import { createField } from './createField';

export type YearInputProps = Omit<NumberInputProps, 'multiline' | 'type'> & {
  properties: YearField['properties'];
};

export const YearInput = React.forwardRef(function YearInput(
  { properties, ...rest }: Omit<YearInputProps, 'ref'>,
  ref: React.Ref<TextInputHandle>,
) {
  return <NumberInput {...rest} ref={ref} decimalPlaces={0} />;
});

export const OldYearInput = React.forwardRef(function YearInput(
  { properties, ...rest }: Omit<YearInputProps, 'ref'>,
  ref: React.Ref<TextInputHandle>,
) {
  return <OldNumberInput {...rest} inputRef={ref} />;
});

export const YearInputField = createField(ObjectFieldTypes.YEAR, YearInput);
