import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import Root from './Root';
import './assets/css/main.css';
import './assets/css/rightclick-menu.css';
import awsconfig from './aws-exports';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);
