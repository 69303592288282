import immer from 'immer';
import _ from 'lodash';
import React, { useContext } from 'react';

import { objInfoMap } from 'src/app-env';
import { Form, Modal, ModalForm } from 'src/components/bappo-components/src';
import { ObjectFieldTypes } from 'src/types/object';

import DatabaseField from '../ObjectField/Input/DatabaseField';
import GenericInputField from '../ObjectField/Input/WrappedGenericInput';

type Manager = {
  open: (
    element: React.ReactElement,
  ) => {
    updateProps: (props: any) => void;
  };
  close: () => void;
  elements: React.ReactElement[];
};

const PopupManagerContext = React.createContext<Manager | undefined>(undefined);

export function PopupManager({
  children,
}: {
  children: (manager: Manager) => JSX.Element;
}) {
  const [modals, setModals] = React.useState<React.ReactElement[]>([]);

  const updateModal = React.useCallback((modalKey: string, newProps: any) => {
    setModals((prevModals) =>
      immer(prevModals, (draftModals) => {
        const modalIndex = draftModals.findIndex(
          (modal) => modal.key === modalKey,
        );
        if (modalIndex >= 0) {
          draftModals[modalIndex] = React.cloneElement(
            prevModals[modalIndex],
            newProps,
          );
        }
      }),
    );
  }, []);

  const manager: Manager = React.useMemo(() => {
    return {
      open: (element) => {
        const key = _.uniqueId('popup');
        setModals((prevModals) => [
          ...prevModals,
          React.cloneElement(element, {
            key,
          }),
        ]);
        return {
          updateProps: (props) => updateModal(key, props),
        };
      },
      close: () => {
        setModals((prevModals) => prevModals.slice(0, -1));
      },
      elements: modals,
    };
  }, [modals, updateModal]);

  return (
    <PopupManagerContext.Provider value={manager}>
      {children(manager)}
    </PopupManagerContext.Provider>
  );
}

function recursiveTrimValues<T>(v: T): T {
  if (typeof v === 'object' && v !== null) {
    return _.mapValues(v as any, recursiveTrimValues) as any;
  } else if (_.isString(v)) {
    return v.trim() as any;
  }
  return v;
}

export function usePopup() {
  const popupManager = useContext(PopupManagerContext)!;

  return {
    form<Values>({
      title,
      initialValues = {},
      onCancel,
      onSubmit,
      submitButton,
      testID,
      // object as template
      objectKey: defaultObjectKey,
      fields: fieldConfigs,
    }: {
      title?: string;
      initialValues?: Partial<Values>;
      onCancel?: (values: Values) => any;
      onSubmit: (values: Values) => any;
      submitButton?: {
        label?: string;
      };
      testID?: string;
      objectKey?: string;
      fields: any[];
    }) {
      const handleCancel = (values: Values) => {
        onCancel && onCancel(recursiveTrimValues(values));
      };
      if (Array.isArray(fieldConfigs) && fieldConfigs.length > 0) {
        // opening a form programmatically

        const fields: React.ReactElement[] = [];
        const predefinedInitialValues: {
          [fieldName: string]: string | boolean;
        } = {};

        const fieldNameMap = new Map();
        fieldConfigs.forEach((fieldConfig) => {
          // custom field that's not in the database
          let type;
          let properties;
          // db field
          let objectKey = defaultObjectKey;
          let fieldName: string;
          // common
          let name;
          let label;
          let autoFocus;
          let readOnly;
          let required;
          let validate;
          let props = {};

          if (_.isString(fieldConfig)) {
            // if fieldConfig is a string, it must be the fieldName of a db field
            fieldName = fieldConfig;
            name = fieldName;
          } else if (_.isPlainObject(fieldConfig)) {
            // otherwise fieldConfig must be an object
            ({
              name,
              type,
              properties,
              objectKey = defaultObjectKey,
              fieldName,
              label,
              autoFocus,
              readOnly,
              required,
              validate,
              props = {},
            } = fieldConfig);
          } else {
            throw new Error('Invalid field config');
          }
          if (!type && !fieldName) {
            throw new Error(`Either 'type' or 'fieldName' must be specified`);
          }

          if (type) {
            // custom field
            if (!Object.values(ObjectFieldTypes).includes(type)) {
              throw new Error(`Invalid field type ${type}`);
            }
            if (!name) {
              throw new Error(`'name' is missing in field config`);
            }
            if (fieldNameMap.has(name)) {
              throw new Error(`Found duplicate field name '${name}'`);
            }
            fieldNameMap.set(name, true);
            fields.push(
              <Form.Field
                key={name}
                component={GenericInputField}
                label={label || name}
                name={name}
                validate={validate}
                props={{
                  fieldDefinition: {
                    type,
                    properties,
                    required,
                  },
                  autoFocus,
                  readOnly,
                  ...props,
                }}
              />,
            // fields.push(
            //   <GenericInputField
            //     key={name}
            //     label={label || name}
            //     name={name}
            //     validate={validate}
            //     fieldDefinition={{
            //       type,
            //       properties,
            //       required,
            //     }}
            //     {...props}
            //     autoFocus={autoFocus}
            //     readOnly={readOnly}
            //   />,
            );
          } else {
            // db field
            if (!objectKey) {
              throw new Error(`'objectKey' is missing in field config`);
            }
            const objInfo = objInfoMap[objectKey];
            if (!objInfo) {
              throw new Error(`Unknown object ${objectKey}`);
            }
            if (!fieldName) {
              throw new Error(`'fieldName' is missing in field config`);
            }
            const fieldDefinition = objInfo.fields.find((f) =>
              f.type === ObjectFieldTypes.REFERENCE
                ? fieldName === `${f.name}_id`
                : fieldName === f.name,
            );
            if (!fieldDefinition) {
              throw new Error(
                `Unknown field ${fieldName} in object ${objectKey}`,
              );
            }

            // compute initial value
            const defaultValue =
              fieldDefinition.properties &&
              fieldDefinition.properties.defaultValue;
            if (typeof defaultValue !== 'undefined') {
              predefinedInitialValues[fieldName] = defaultValue;
            }

            fields.push(
              <DatabaseField
                key={name}
                label={label}
                name={name}
                objectKey={objectKey}
                fieldName={fieldName}
                autoFocus={autoFocus}
                readOnly={readOnly || fieldName.startsWith('_')} // built-in field must be read-only
                required={required || fieldDefinition.required}
              />,
            );
          }
        });
        const handleSubmit = async (values: Values) => {
          const res = await onSubmit(recursiveTrimValues(values));
          return res;
        };

        const { updateProps } = popupManager.open(
          <ModalForm
            initialValues={{
              ...predefinedInitialValues,
              ...initialValues,
            }}
            onRequestClose={popupManager.close}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            submitButtonText={submitButton?.label}
            testID={testID || 'record-form'}
            title={title}
          >
            {fields}
          </ModalForm>,
        );
        // input cannot autofocus without this hack
        requestAnimationFrame(() => {
          updateProps({
            visible: true,
          });
        });
      }
    },
    open(body: React.ReactElement) {
      const { updateProps } = popupManager.open(
        <Modal onRequestClose={popupManager.close}>{body}</Modal>,
      );
      // input cannot autofocus without this hack
      requestAnimationFrame(() => {
        updateProps({
          visible: true,
        });
      });
    },
    close() {
      popupManager.close();
    },
  };
}
