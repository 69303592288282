import navlab_clear_bg from 'src/assets/images/navlab_clear_bg.png'
import pencil_blue from 'src/assets/images/pencil_blue.png'
import magnify_glass_blue from 'src/assets/images/magnify_glass_blue.png'

const sources = {
  navlab_clear_bg: {
    uri: navlab_clear_bg,
  },
  magnify_glass_blue: {
    uri: magnify_glass_blue,
  },
  pencil_blue: {
    uri: pencil_blue,
  },
};

export default sources;
