import React from 'react';

import OldNumberInput from 'src/components/BaseComponents/NumberInput';
import {
  NumberInput,
  NumberInputProps,
} from 'src/components/BaseComponents/NumberInput/NumberInput';
import { TextInputHandle } from 'src/components/bappo-components/src/primitives/TextInput/types';
import { CurrencyField, ObjectFieldTypes } from 'src/types/object';
import { getCurrencyDecimal } from 'src/utils/FieldUtils';

import { createField } from './createField';

export type CurrencyInputProps = Omit<
  NumberInputProps,
  'multiline' | 'type'
> & {
  properties: CurrencyField['properties'];
};

export const CurrencyInput = React.forwardRef(function CurrencyInput(
  { properties, ...rest }: Omit<CurrencyInputProps, 'ref'>,
  ref: React.Ref<TextInputHandle>,
) {
  const { currencyType } = properties;

  return (
    <NumberInput
      {...rest}
      ref={ref}
      decimalPlaces={getCurrencyDecimal(currencyType)}
    />
  );
});

export const OldCurrencyInput = React.forwardRef(function CurrencyInput(
  { properties, ...rest }: Omit<CurrencyInputProps, 'ref'>,
  ref: React.Ref<TextInputHandle>,
) {
  const { currencyType } = properties;

  return (
    <OldNumberInput
      {...rest}
      decimalPlaces={getCurrencyDecimal(currencyType)}
      unit={currencyType}
      inputRef={ref}
    />
  );
});

export const CurrencyInputField = createField(
  ObjectFieldTypes.CURRENCY,
  CurrencyInput,
);
