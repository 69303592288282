import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useCurrentUser } from 'src/apis/AuthenticatedRequester';
import { useSetPageTitle } from 'src/components/Page';
import {
  ActivityIndicator,
  Image,
  ScrollView,
  Text,
  TouchableView,
  View,
  styled,
} from 'src/components/bappo-components/src';

import sources from './imagesource';

export function HomePage() {
  const setPageTitle = useSetPageTitle();

  useEffect(() => setPageTitle('Home'), []);

  // Manage Loading indicator
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setLoading(false);
  }, []);
  const renderLoading = (color) => {
    return (
      <ActivityIndicator color={color} size="large" style={{ margin: 16 }} />
    );
  };

  // Setup Variables
  const userInfo = useCurrentUser();
  const userName = userInfo?.name || '';
  const greeting = `Hi ${userName}\nWelcome to Navlab.`;

  // Navigation Buttons
  const findRateNav = () => {
    history.push('rate-finder');
  };
  const makeQuoteNav = () => {
    history.push('quote-list');
  };
  const maintenanceNav = () => {
    history.push('rate-maintenance');
  };
  const businessHubNav = () => {
    console.log('businessHubNav pressed');
    // $navigation.navigate("Department List Page");
  };
  const myStatsNav = () => {
    history.push('dashboard');
  };
  const helpNav = async () => {
    history.push('help');
  };

  if (loading) {
    return renderLoading('#1f2833');
  } else {
    return (
      <PageContainer>
        <ColContainer style={{ minHeight: '100%' }}>
          <RowContainer style={{ flex: '2 2 16em' }}>
            <Image source={sources.navlab_clear_bg} style={styles.image} />
            <Image source={sources.pencil_blue} />
            <PageText style={{ flex: '1 1' }}>{greeting}</PageText>
            {
              // hide until developed
              false && (
                <NewsFeed style={{ flex: '3 3 ' }}>
                  {'NewsFeed\n\n\nThis is your news.'}
                </NewsFeed>
              )
            }
          </RowContainer>
          <RowContainer style={{ flex: '3 3 24em' }}>
            <HeaderText style={{ flex: '1 1 ' }}>
              What would you like to do?
            </HeaderText>
            <ColContainer style={{ flex: '8 8 ' }}>
              <InnerRowContainer>
                <MajorButton onPress={findRateNav}>
                  {/*<IconButton
                    name="search"
                    color={teal}
                    size={150}
                    onPress={findRateNav}
                  />*/}
                  <Image
                    source={sources.magnify_glass_blue}
                    style={styles.imageFlip}
                  />
                  <ButtonText>Find a Rate</ButtonText>
                </MajorButton>
                <MinorButton onPress={maintenanceNav}>
                  <ButtonText>Maintenance</ButtonText>
                </MinorButton>
                <MinorButton onPress={myStatsNav}>
                  <ButtonText>My Stats</ButtonText>
                </MinorButton>
              </InnerRowContainer>
              <InnerRowContainer>
                <MajorButton onPress={makeQuoteNav}>
                  {/*<IconButton
                    name="edit"
                    color={teal}
                    size={150}
                    onPress={makeQuoteNav}
                  /> */}
                  <Image source={sources.pencil_blue} style={styles.image} />
                  <ButtonText>Make a Quote</ButtonText>
                </MajorButton>
                <MinorButton onPress={businessHubNav}>
                  <ButtonText>Business Hub</ButtonText>
                </MinorButton>
                <MinorButton onPress={helpNav}>
                  <ButtonText>Help</ButtonText>
                </MinorButton>
              </InnerRowContainer>
            </ColContainer>
          </RowContainer>
        </ColContainer>
      </PageContainer>
    );
  }
}

export default (props) => <HomePage {...props} />;

// Color Palette
const backgroundNavy = '#1f2833';
const teal = '#38b2de';
const midGreen = '#82c44e';
const offGreen = '#80c57f';
const blue = '#4b64ad';
const grey = '#bacad5';

const PageContainer = styled(ScrollView)`
  background-color: #1f2833;
`;

const ColContainer = styled(View)`
  background-color: #1f2833;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2px;
  justify-content: space-around;
  item-align: center;
`;

const RowContainer = styled(View)`
  flex-wrap: wrap;
  margin: 48px;
  justify-content: space-between;
`;

const InnerRowContainer = styled(RowContainer)`
  margin: 0px;
  justify-content: space-evenly;
`;

const PageText = styled(Text)`
  color: white;
  font-size: 24px;
  margin: 8px 16px 8px 16px;
  flex: 1 1 1em;
  font-family: 'Blome Light', 'Proxima Nova', sans-serif;
`;
const ButtonText = styled(Text)`
  color: inherit;
  font-size: 36px;
  text-align: center;
  margin: 8px 8px 8px 8px;
  font-family: 'Blome Light', 'Proxima Nova', sans-serif;
`;

const HeaderText = styled(PageText)`
  margin: 2em 0em 0em 0em;
  font-size: 3rem;
  text-align: center;
`;

const NewsFeed = styled(Text)`
  color: white;
  font-size: 16px;
  margin: 10px;
  flex: 1 1 1em;
  border: 2px solid;
  border-radius: 0.5em;
  text-align: center;
`;

const MajorButton = styled(TouchableView)`
  border: 2px solid #38b2de;
  border-radius: 16px;
  color: #38b2de;
  // align-items: center;
  font-size: 24px;
  flex-direction: column;
  justify-content: center;
  min-width: 14em;
  height: 14em;
  margin: 24px 0px 24px 0px;
  padding: 1.5em;
`;

const MinorButton = styled(MajorButton)`
  border: 2px solid #80c57f;
  color: #80c57f;
  border-radius: 16px;
  padding: 16px;
  height: auto;
  margin: 8px 0px 8px 0px;
`;

const styles = {
  image: {
    borderWidth: '0',
    flex: '1 1 5em',
    paddingTop: '8px',
  },
  imageFlip: {
    borderWidth: '0',
    flex: '1 1 5em',
    paddingTop: '8px',
    transform: 'scaleX(-1)',
  },
};
