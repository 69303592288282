import React from 'react';

import { TextInput as BaseTextInput } from 'src/components/bappo-components/src';
import { TextInputProps as BaseTextInputProps } from 'src/components/bappo-components/src/primitives/TextInput/types';
import { ObjectFieldTypes, TextField } from 'src/types/object';

import { createField } from './createField';

export type TextInputProps = Omit<BaseTextInputProps, 'multiline' | 'type'> & {
  properties: TextField['properties'];
};

export const TextInput = React.forwardRef(function TextInput(
  { properties, value, ...rest }: TextInputProps,
  ref: React.Ref<BaseTextInput>,
) {
  return (
    <BaseTextInput
      {...rest}
      multiline={false}
      type="text"
      value={value || ''}
      ref={ref}
    />
  );
});

export const TextInputField = createField(ObjectFieldTypes.TEXT, TextInput);
