// @ts-ignore
import React from 'react';
import styled from 'styled-components';

import InputBase from '../../internals/web/InputBase';
import FontContext from '../Font/FontContext';
import { FileInputProps } from './types';

type Props = FileInputProps & {
  // Will be removed
  className?: string;
};

class FileInput extends React.Component<Props> {
  static defaultProps = {
    autoFocus: false,
    placeholder: '',
    readOnly: false,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  static displayName = 'FileInput';

  public blur() {
    this._input && this._input.blur();
  }

  public clear() {
    if (this._input) {
      this._input.filename = '';
    }
  }

  public focus() {
    this._input && this._input.focus();
  }

  // static getDerivedStateFromProps(nextProps: Props) {
  //   FileInput._checkProps(nextProps);
  //   return null;
  // }

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => this.focus());
    }
  }

  render() {
    const {
      accessibilityLabel,
      className,
      placeholder,
      readOnly,
      style,
      testID,
      value,
    } = this.props;

    const props = {
      accessibilityLabel,
      ref: this._captureInputRef,
      onBlur: this._createBlurEventHandler(),
      onChange: this._createChangeEventHandler(),
      onFocus: this._createFocusEventHandler(),
      placeholder,
      readOnly,
      testID,
      filename: value,
      type: 'file'
    };

    return (
      <FontContext.Consumer>
        {({ fontFamily, fontSize }) => {
          const styleProps = {
            className,
            style,
            $fontFamily: fontFamily,
            $fontSize: fontSize,
          };
          //@ts-ignore
          return <Input {...props} {...styleProps} />;
        }}
      </FontContext.Consumer>
    );
  }

  private _input: HTMLInputElement | null = null;

  private _captureInputRef = (
    ref: HTMLInputElement | null,
  ) => {
    this._input = ref;
  };

  private _createBlurEventHandler = () => {
    const { onBlur } = this.props;

    if (onBlur) {
      return (
        event: React.FocusEvent<HTMLInputElement>,
      ) => {
        onBlur({
          nativeEvent: {
            value: event.currentTarget.value,
          },
        });
      };
    }
    return onBlur;
  };

  private _createChangeEventHandler = () => {
    const { onValueChange } = this.props;

    if (onValueChange) {
      return (event: any) => {
        onValueChange(event.target.files?.[0]);
      };
    }
    return onValueChange;
  };

  private _createFocusEventHandler = () => {
    const { onFocus } = this.props;

    if (onFocus) {
      return (
        event: React.FocusEvent<HTMLInputElement>,
      ) => {
        onFocus({
          nativeEvent: {
            value: event.currentTarget.value,
          },
        });
      };
    }
    return onFocus;
  };
}

export default FileInput;

type InputProps = {
  $fontFamily: string;
  $fontSize: string;
};

const Input = styled(InputBase)<InputProps>`
  font-family: ${(props) => props.$fontFamily};
  font-size: ${(props) => props.$fontSize}px;
`;
