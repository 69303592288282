import React from 'react';

import {
  Switch,
  SwitchFieldWrapper,
  useFieldState,
} from 'src/components/bappo-components/src';
import {
  InputField,
  InputFieldComponent,
} from 'src/components/bappo-components/src/primitives/Form/types';
import { SwitchProps } from 'src/components/bappo-components/src/primitives/Switch/types';
import { CheckboxField } from 'src/types/object';

export type SwitchInputProps = SwitchProps & {
  properties: CheckboxField['properties'];
};

export const SwitchInput = React.forwardRef(function SwitchInput(
  { properties, ...rest }: SwitchInputProps,
  ref: React.Ref<Switch>,
) {
  return <Switch {...rest} ref={ref} />;
});

export const SwitchInputField: InputFieldComponent<
  boolean | null,
  SwitchInputProps
> = React.forwardRef(function SwitchInputField(
  props,
  ref: React.Ref<InputField>,
) {
  const {
    fieldState: passedFieldState,
    label,
    name,
    properties,
    required,
    reserveErrorSpace,
    testID,
    validate: extraValidators,
    value,
    ...rest
  } = props;

  const inputRef = React.useRef<any>(null);
  const focusInput = React.useCallback(
    () => inputRef.current && inputRef.current.focus(),
    [],
  );
  const blurInput = React.useCallback(
    () => inputRef.current && inputRef.current.blur(),
    [],
  );
  React.useImperativeHandle(ref, () => ({
    focus: focusInput,
    blur: blurInput,
  }));

  const { fieldState, onBlur, onFocus, onValueChange } = useFieldState<
    boolean | null
  >({
    ...props,
    validate: extraValidators,
  });

  return (
    <SwitchFieldWrapper
      fieldState={fieldState}
      focusInput={focusInput}
      label={label}
      // Checkbox field does not have validators by default. Only reserve error
      // space if there are custom validators.
      reserveErrorSpace={!!extraValidators}
      testID={testID}
    >
      <SwitchInput
        {...rest}
        value={!!fieldState.value}
        ref={inputRef}
        onBlur={onBlur}
        onFocus={onFocus}
        onValueChange={onValueChange}
        properties={properties}
      />
    </SwitchFieldWrapper>
  );
});
