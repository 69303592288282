import useCachedRecords from './useCachedRecords';

export default function GetCachedRecordsById({
  children,
  objectKey,
  ids,
  attributes,
  include,
}) {
  const cachedRecords = useCachedRecords({
    objectKey,
    ids,
    attributes,
    include,
  });

  return children({
    data: cachedRecords,
  });
}
