import React from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import { View } from 'src/components/bappo-components/src';
import { InputField } from 'src/components/bappo-components/src/primitives/Form/types';
import { TextInputProps } from 'src/components/bappo-components/src/primitives/TextInput/types';
import { ObjectFieldTypes, TextAreaField } from 'src/types/object';

import { createField } from './createField';

export type TextAreaInputProps = Omit<TextInputProps, 'multiline' | 'type'> & {
  active?: boolean;
  className?: string;
  properties: TextAreaField['properties'];
  rowsDisplayed?: number;
};

export const TextAreaInput = React.forwardRef(function TextAreaInput(
  {
    active,
    className,
    onBlur,
    onFocus,
    onValueChange,
    rowsDisplayed = 8,
    style,
    testID,
    value,
    ...rest
  }: TextAreaInputProps,
  ref: React.Ref<InputField>,
) {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  React.useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
    blur: () => inputRef.current?.blur(),
  }));

  return (
    <Container className={className} style={style} testID={testID}>
      <TextAreaAutosize
        {...rest}
        ref={inputRef}
        maxRows={rowsDisplayed}
        onBlur={(event) =>
          onBlur?.({
            nativeEvent: {
              text: event.currentTarget.value,
            },
          })
        }
        onChange={(event) => onValueChange?.(event.currentTarget.value)}
        onFocus={(event) =>
          onFocus?.({
            nativeEvent: {
              text: event.currentTarget.value,
            },
          })
        }
        value={value || ''}
        style={textAreaStyle} // using inline style because styled-components does not pass down ref
      />
      <NewLineMark $show={active}>↵ to new line</NewLineMark>
    </Container>
  );
});

export const TextAreaInputField = createField(
  ObjectFieldTypes.TEXTAREA,
  TextAreaInput,
);

const textAreaStyle = {
  font: 'inherit',
  fontSize: 14,
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  padding: 0,
  resize: 'none',
  transition: 'border-color 0.3s ease-in-out',
  width: '100%',
} as const;

const Container = styled(View)``;

const NewLineMark = styled.div<{
  $show?: boolean;
}>`
  ${(props) => !props.$show && 'display: none;'};
  position: absolute;
  color: rgba(0, 0, 0, 0.298039);
  right: 10px;
  bottom: 0px;
  font-size: 12px;
`;
