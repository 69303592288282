import _ from 'lodash';
import moment from 'moment';

import { SystemDateFormats } from 'src/constants/DateFormats';
import { ObjectFieldTypes } from 'src/types/object';

export function getDefaultValueByType(type) {
  switch (type) {
    case ObjectFieldTypes.CHECKBOX:
      return false;
    case ObjectFieldTypes.TEXT:
    case ObjectFieldTypes.TEXTAREA:
      return '';
    default:
      return null;
  }
}

/**
 * Get the default value of a field type
 *
 * @param {Object} field - Field definition
 * @returns {*} a default value
 */
export function getDefaultValue(field) {
  return _.get(
    field.properties,
    'defaultValue',
    getDefaultValueByType(field.type),
  );
}

/**
 * Get the field value of an object instance
 *
 * @param {Object} item - Object instance or field entity
 * @param {Object} field - Field definition
 * @returns {*} the field value of the object instance
 */
export function getFieldValue(item, field) {
  if (field.type === ObjectFieldTypes.REFERENCE) {
    return _.get(item, [field.name, 'id']);
  }
  return _.get(item, [field.name]);
}

/**
 * Get Decimal Places of a Specific Currency Type
 * @param {string} type of the currency
 * @returns {number} length of decimal places
 **/
export function getCurrencyDecimal(currency) {
  switch (currency) {
    case 'JPY':
    case 'KRW':
    case 'VND':
      return 0;
    default:
      return 2;
  }
}

/**
 * Adds commas to a currency amount
 *
 * @param {string|number} amount - Currency amount
 * @returns {string} amount with commas
 **/
export function addCommas(amount) {
  if (amount == null) {
    return '';
  }

  const x = amount.toString().split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;
}

/**
 * Make sure passed is a string
 * @param {Any} possible string
 * @returns {string} original string or empty string
 **/
export function formatTextValue(value) {
  return _.isString(value.toSt) ? value : '';
}

/**
 * Convert a number or a string to a valid format
 *
 * @param {number|string} num - Number or string to be formatted
 * @param {number}        [decimalPlaces=0] - Number of decimal places
 * @returns {string} Formatted number
 */
export function formatNumber(num, decimalPlaces = 0) {
  if (num == null) {
    return null;
  }

  const numStr = _.isString(num)
    ? num.replace(/[^0-9.\-+]/g, '').replace(/^(-|\+)?(\..*)$/, '$10$2')
    : num.toString();
  const fractionRegex = decimalPlaces > 0 ? `\\.\\d{0,${decimalPlaces}}` : '';
  const regex = new RegExp(`^(\\-|\\+)?\\d+(${fractionRegex})?`);
  const matches = numStr.match(regex);

  if (matches) {
    // if string can be formatted to a valid number
    const formattedStr = matches[0];
    const decimalPointIndex = formattedStr.indexOf('.');

    // pad with zeros
    if (decimalPointIndex > -1) {
      // decimal point found
      const fractionLength = formattedStr.substring(decimalPointIndex + 1)
        .length;
      const zeroPadding = new Array(decimalPlaces + 1 - fractionLength).join(
        '0',
      );
      return `${formattedStr}${zeroPadding}`;
    } else if (decimalPlaces > 0) {
      const zeroPadding = new Array(decimalPlaces + 1).join('0');
      return `${formattedStr}.${zeroPadding}`;
    }
    return formattedStr;
  }
  return null;
}

/**
 * Return formatted Currency value, with correct precision
 * @param {number} amount of the currency
 * @param {string} type of the currency
 * @returns {number} length of decimal places
 **/
export function formatCurrency(amount, type) {
  const decimalPlaces = getCurrencyDecimal(type);
  return addCommas(formatNumber(amount, decimalPlaces));
}

export const monthOptions = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export const monthOptionsWithNull = [
  { label: '--- No Value ---', value: null },
  ...monthOptions,
];

export function monthOptionFilter(searchText, option) {
  const formattedSearchText = searchText.toLowerCase();
  if (option.label.toLowerCase().includes(formattedSearchText)) {
    return true;
  }
  return option.value !== null && `${option.value}` === formattedSearchText;
}

export function formatDateString(value = null, format = 'DD/MM/YYYY') {
  return moment(value, SystemDateFormats.DATE).isValid()
    ? moment(value, SystemDateFormats.DATE).format(format)
    : '';
}

export function formatTimeString(value = null, format = 'h:mm A') {
  return moment(value, SystemDateFormats.TIME).isValid()
    ? moment(value, SystemDateFormats.TIME).format(format)
    : '';
}

export function formatValue({ type, properties }, value) {
  if (value == null) return '';
  switch (type) {
    case ObjectFieldTypes.CHECKBOX: {
      const options = [
        { id: true, label: properties.trueLabel },
        { id: false, label: properties.falseLabel },
      ];
      const option = options.find((op) => op.id === value);
      if (!option) return null;
      return option.label;
    }
    case ObjectFieldTypes.DATE:
      return formatDateString(value, properties.format);
    case ObjectFieldTypes.FIXED_LIST: {
      const options = properties.options;
      const option = options.find((op) => op.id === value);
      if (!option) return null;
      return option.label;
    }
    case ObjectFieldTypes.MONTH: {
      const option = monthOptions.find((op) => op.value === value);
      if (!option) return null;
      return option.label;
    }
    case ObjectFieldTypes.TIME:
      return formatTimeString(value, properties.format);
    default:
      return value;
  }
}

export function isTextField(fieldDefinition) {
  return [
    ObjectFieldTypes.CURRENCY,
    ObjectFieldTypes.EMAIL,
    ObjectFieldTypes.PHONENUMBER,
    ObjectFieldTypes.QUANTITY,
    ObjectFieldTypes.TEXT,
    ObjectFieldTypes.TEXTAREA,
    ObjectFieldTypes.YEAR,
  ].includes(fieldDefinition.type);
}

export function isFieldRequired(fieldDefinition) {
  return (
    fieldDefinition.required &&
    ![ObjectFieldTypes.AUTOINCREMENT, ObjectFieldTypes.CHECKBOX].includes(
      fieldDefinition.type,
    )
  );
}
