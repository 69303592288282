import React from 'react';

import {
  InputFieldWrapper,
  SwitchFieldWrapper,
  View,
  styled,
} from 'src/components/bappo-components/src';
import { ObjectFieldTypes } from 'src/types/object';
import { isFieldRequired } from 'src/utils/FieldUtils';
import { required as requiredValidator } from 'src/utils/ValidationUtils';

import { AutoIncrementInput } from './AutoIncrement';
import { OldCurrencyInput as CurrencyInput } from './Currency';
import { DateInput } from './Date';
import { EmailInput } from './Email';
import { FixedListInput } from './FixedList';
import { MonthInput } from './Month';
import { PhoneNumberInput } from './PhoneNumber';
import { OldQuantityInput as QuantityInput } from './Quantity';
import { SwitchInput } from './Switch';
import { TextInput } from './Text';
import { TextAreaInput } from './TextArea';
import { TimeInput } from './Time';
import { OldYearInput as YearInput } from './Year';

class GenericInputField extends React.Component {
  static displayName = 'GenericInputField';

  render() {
    const {
      className,
      fieldDefinition = {},
      fieldState,
      style,
      testID,
      meta,
      ...rest
    } = this.props;

    const { type, properties } = fieldDefinition;
    const required = isFieldRequired(fieldDefinition);
    const validate = required
      ? [
          requiredValidator({
            msg: `${
              rest.label || fieldDefinition.displayName || rest.name
            } is required`,
          }),
          ...(Array.isArray(rest.validate)
            ? rest.validate
            : rest.validate
            ? [rest.validate]
            : []),
        ]
      : rest.validate;

    const wrapperProps = {
      ...rest,
      fieldState: {
        ...(meta || {}),
        ...fieldState,
      },
      focusInput: () =>
        this._inputRef.current && this._inputRef.current.focus(),
      required,
      testID,
    };

    const inputProps = {
      ...rest,
      ref: this._inputRef,
      validate,
    };

    let content;

    if (type === ObjectFieldTypes.CHECKBOX) {
      content = (
        <SwitchFieldWrapper {...wrapperProps} reserveErrorSpace={false}>
          <SwitchInput {...inputProps} properties={properties} />
        </SwitchFieldWrapper>
      );
    } else {
      switch (type) {
        case ObjectFieldTypes.AUTOINCREMENT:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <AutoIncrementInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.CURRENCY:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <CurrencyInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.DATE:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <DateInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.EMAIL:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <EmailInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.FIXED_LIST:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <FixedListInput
                {...inputProps}
                clearable={!required}
                properties={properties}
              />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.MONTH:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <MonthInput
                {...inputProps}
                clearable={!required}
                properties={properties}
              />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.PHONENUMBER:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <PhoneNumberInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.QUANTITY:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <QuantityInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.TEXT:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <TextInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.TEXTAREA:
          content = (
            <InputFieldWrapper {...wrapperProps} style={{ height: 'auto' }}>
              <TextAreaInput
                {...inputProps}
                active={fieldState.active}
                properties={properties}
              />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.TIME:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <TimeInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        case ObjectFieldTypes.YEAR:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <YearInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
        default:
          content = (
            <InputFieldWrapper {...wrapperProps}>
              <TextInput {...inputProps} properties={properties} />
            </InputFieldWrapper>
          );
          break;
      }
    }

    return (
      <Container className={className} style={style}>
        {content}
      </Container>
    );
  }

  _inputRef = React.createRef();
}

export default GenericInputField;

const Container = styled(View)`
  width: 100%;
`;
