import React from 'react';

const MAX_RETRY = 5;
const INITIAL_RETRY_TIMEOUT = 1000;

export function lazyWithRetry<T extends React.ComponentType<any>>(
  load: () => Promise<{ default: T }>,
): React.LazyExoticComponent<T> {
  if (typeof window === 'undefined') {
    return React.lazy(load);
  }

  return React.lazy(async function loadChunkWithRetry() {
    let lastError = null;
    let retryTimeout = INITIAL_RETRY_TIMEOUT;
    for (let retryCount = 0; retryCount <= MAX_RETRY; retryCount++) {
      try {
        const result = await load();
        return result;
      } catch (err) {
        lastError = err;
        if (retryCount > MAX_RETRY) break;
        await sleep(retryTimeout);
        retryTimeout *= 2;
      }
    }
    throw lastError;
  });
}

async function sleep(milliseconds: number): Promise<void> {
  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}
