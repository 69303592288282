export enum ObjectFieldTypes {
  AUTOINCREMENT = 'AutoIncrement',
  CHECKBOX = 'Checkbox',
  COLOR = 'Color',
  CURRENCY = 'Currency',
  DATE = 'Date',
  DATE_TIME = 'DateTime',
  EMAIL = 'Email',
  FIXED_LIST = 'FixedList',
  ICON = 'Icon',
  MONTH = 'Month',
  PHONENUMBER = 'PhoneNumber',
  QUANTITY = 'Quantity',
  REFERENCE = 'Reference',
  TEXT = 'Text',
  TEXTAREA = 'TextArea',
  TIME = 'Time',
  UUID = 'UniqueID',
  YEAR = 'Year',
}

export enum RelationshipType {
  BELONGS_TO = 'BelongsTo',
  BELONGS_TO_MANY = 'BelongsToMany',
  HAS_MANY = 'HasMany',
  HAS_ONE = 'HasOne',
}

export interface ObjectFieldBase {
  displayName: string;
  name: string;
  required: boolean;
}
export interface AutoIncrementField extends ObjectFieldBase {
  type: ObjectFieldTypes.AUTOINCREMENT;
  properties: {
    defaultValue?: string;
    firstno: string;
    prefix: string;
  };
}
export interface CheckboxField extends ObjectFieldBase {
  type: ObjectFieldTypes.CHECKBOX;
  properties: {
    defaultValue?: boolean;
    falseLabel?: string;
    trueLabel?: string;
  };
}
export interface CurrencyField extends ObjectFieldBase {
  type: ObjectFieldTypes.CURRENCY;
  properties: {
    defaultValue?: string;
    currencyType: string;
  };
}
export interface DateField extends ObjectFieldBase {
  type: ObjectFieldTypes.DATE;
  properties: {
    defaultValue?: string;
    format?: string;
  };
}
export interface EmailField extends ObjectFieldBase {
  type: ObjectFieldTypes.EMAIL;
  properties: {
    defaultValue?: string;
  };
}
export interface FixedListField extends ObjectFieldBase {
  type: ObjectFieldTypes.FIXED_LIST;
  properties: {
    defaultValue?: string;
    options: {
      id: string;
      label: string;
      pos: number;
    }[];
  };
}
export interface MonthField extends ObjectFieldBase {
  type: ObjectFieldTypes.MONTH;
  properties: {
    defaultValue?: string;
  };
}
export interface PhoneNumberField extends ObjectFieldBase {
  type: ObjectFieldTypes.PHONENUMBER;
  properties: {
    defaultValue?: string;
  };
}
export interface QuantityField extends ObjectFieldBase {
  type: ObjectFieldTypes.QUANTITY;
  properties: {
    defaultValue?: string;
    decimalPlaces: number;
    unit?: string;
    notShowUnit?: boolean;
  };
}
export interface ReferenceField extends ObjectFieldBase {
  type: ObjectFieldTypes.REFERENCE;
  properties: {
    defaultValue?: string;
    refObjectKey: string;
  };
}
export interface TextField extends ObjectFieldBase {
  type: ObjectFieldTypes.TEXT;
  properties: {
    defaultValue?: string;
  };
}
export interface TextAreaField extends ObjectFieldBase {
  type: ObjectFieldTypes.TEXTAREA;
  properties: {
    defaultValue?: string;
  };
}
export interface TimeField extends ObjectFieldBase {
  type: ObjectFieldTypes.TIME;
  properties: {
    defaultValue?: string;
    format?: string;
  };
}
export interface YearField extends ObjectFieldBase {
  type: ObjectFieldTypes.YEAR;
  properties: {
    defaultValue?: string;
  };
}
export type ObjectField =
  | AutoIncrementField
  | CheckboxField
  | CurrencyField
  | DateField
  | EmailField
  | FixedListField
  | MonthField
  | PhoneNumberField
  | QuantityField
  | ReferenceField
  | TextField
  | TextAreaField
  | TimeField
  | YearField;

export interface ObjectRelationship {
  type: RelationshipType;
  targetObjectKey: string;
  targetName: string;
}

export interface ObjectInfo {
  key: string;
  singularName: string;
  pluralName: string;
  isCrossTenant?: boolean;
  /**
   * Includes "_id" fields but excludes "id"
   */
  fieldByNameMap: {
    [fieldName: string]: Pick<ObjectField, 'name' | 'displayName' | 'type'> & {
      objKey?: string;
    };
  };
  fieldByPathMap: {
    [fieldPath: string]: Pick<ObjectField, 'name' | 'displayName' | 'type'> & {
      objKey?: string;
    };
  };
  fields: ObjectField[];
  relationships: ObjectRelationship[];
}
export type ObjectInfoMap = {
  [objKey: string]: ObjectInfo;
};
