import _ from 'lodash';
import React from 'react';

import { FindAndCountAll, FindById } from 'src/apis/rpc';
import { OldSelect as Select } from 'src/components/bappo-components/src';
import { SelectProps } from 'src/components/bappo-components/src/components/OldSelect/types';
import { ObjectFieldTypes, ReferenceField } from 'src/types/object';

import { createField } from './createField';

export type ReferenceInputProps = Pick<
  SelectProps,
  | 'accessibilityLabel'
  | 'autoFocus'
  | 'clearable'
  | 'onBlur'
  | 'onFocus'
  | 'onValueChange'
  | 'placeholder'
  | 'readOnly'
  | 'style'
  | 'testID'
  | 'value'
> & {
  properties: ReferenceField['properties'];
  value?: string | null;
};

type Record = {
  id: string;
  name: string | null;
};

export const ReferenceInput = React.forwardRef(function ReferenceInput(
  { properties, value, ...rest }: ReferenceInputProps,
  ref: React.Ref<typeof Select>,
) {
  const [searchText, setSearchText] = React.useState('');
  const [typing, setTyping] = React.useState(false);
  const updateSearchText = React.useCallback(
    _.debounce((searchText: string) => {
      setSearchText(searchText);
      setTyping(false);
    }, 350),
    [],
  );

  const handleInputChange = (inputValue: string, triggeredByUser: boolean) => {
    if (triggeredByUser) {
      // Pretend to be loading as soon as user starts typing. Actual request
      // will be debounced.
      setTyping(true);
      updateSearchText(inputValue);
    }

    return inputValue;
  };

  const renderSelect = (
    selectedRecord?: Record,
    loadingSelectedRecord?: boolean,
  ) => {
    const defaultLimit = 200;
    return (
      <FindAndCountAll
        objectKey={properties.refObjectKey}
        where={searchText ? { _fullText: searchText } : undefined}
        defaultLimit={defaultLimit}
      >
        {(result: {
          data?: Record[];
          loading: boolean;
          fetchMore: (options?: { limit?: number }) => void;
        }) => {
          const { data, loading, fetchMore } = result;

          const options = data
            ? data
                .filter((record) => record.id !== value)
                .map((record) => ({
                  label: record.name ?? record.id,
                  value: record.id,
                }))
                .sort((a, b) =>
                  a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
                )
            : [];
          // always put selected record as the first option
          if (selectedRecord) {
            options.unshift({
              label: selectedRecord.name ?? selectedRecord.id,
              value,
            });
          }
          const isLoading =
            loading || (loadingSelectedRecord && !selectedRecord) || typing;
          return (
            <Select
              {...rest}
              ref={ref}
              isLoading={isLoading}
              onInputChange={handleInputChange}
              placeholder="Type to search"
              noResultsText={isLoading ? 'Loading...' : 'No results found'}
              options={options}
              onDropdownEndReached={() => {
                fetchMore({ limit: defaultLimit });
              }}
              // onDropdownEndReachedThreshold={0.1}
              value={selectedRecord && selectedRecord.id}
            />
          );
        }}
      </FindAndCountAll>
    );
  };

  return value ? (
    <FindById objectKey={properties.refObjectKey} recordId={value}>
      {({ data, loading }: { data?: Record; loading: boolean }) =>
        renderSelect(data, loading)
      }
    </FindById>
  ) : (
    renderSelect()
  );
});

export const ReferenceInputField = createField(
  ObjectFieldTypes.REFERENCE,
  ReferenceInput,
);
