import _ from 'lodash';
import React, { useContext } from 'react';

import {
  useAuthenticatedRequester,
  useCurrentUser,
} from '../AuthenticatedRequester';
import Cache from './InMemoryCache';
import {
  getResultFromCacheAccordingToAttributesAndInclude,
  recordCacheIdType,
} from './helpers';

export const ApiClientContext = React.createContext();

export function ApiClient({ children }) {
  const getRequester = useAuthenticatedRequester();
  const currentUser = useCurrentUser();

  const cache = useCache(currentUser.email);

  const makeTransactionRpcRequest = React.useCallback(
    async function makeTransactionRpcRequest(data) {
      const url = `/api/v0/rpc`;
      const response = await getRequester()({
        method: 'post',
        url,
        data,
      });
      return response;
    },
    [getRequester],
  );

  const subscribersByEventRef = React.useRef({
    new_message: [],
    own_message: [],
    leave_conversation: [],
    workhorse: [],
  });

  const query = React.useCallback(
    async ({ method, params }) => {
      const {
        data: { error, result },
      } = await makeTransactionRpcRequest({
        query: method.buildQuery
          ? method.buildQuery(params)
          : {
              method: method.name,
              params,
            },
      });

      if (error) {
        const err = new Error(error.message);
        err.data = error.data;
        throw err;
      }

      // cache records
      cache.writeRecords(result);

      return result;
    },
    [cache, makeTransactionRpcRequest],
  );

  const mutate = React.useCallback(
    async ({ method, params }) => {
      // send request
      const {
        data: { error, result },
      } = await makeTransactionRpcRequest({
        query: method.buildQuery
          ? method.buildQuery(params)
          : {
              method: method.name,
              params,
            },
      });

      // handle error
      if (error) {
        const err = new Error(error.message);
        err.data = error.data;
        throw err;
      }

      // clear cache if needed
      const invalidationOptions = method.invalidate || {
        // by default clear all query results and keep all records
        shouldInvalidateRecord: () => false,
      };
      cache.invalidate(invalidationOptions);

      // cache records
      cache.writeRecords(result);

      return result;
    },
    [cache, makeTransactionRpcRequest],
  );

  const subscribe = React.useCallback(({ event, onData }) => {
    if (!subscribersByEventRef.current[event]) {
      return () => {};
    }

    const id = _.uniqueId('apiClient:subscription');
    subscribersByEventRef.current[event].push({
      id,
      onData,
    });
    return () => {
      subscribersByEventRef.current[event] = subscribersByEventRef.current[
        event
      ].filter((sub) => sub.id !== id);
    };
  }, []);

  const denormalizeResult = React.useCallback(
    ({ method, params }, normalizedResult) => {
      return method.getResultFromCache(
        normalizedResult,
        cache.denormalizeRecord.bind(cache),
        params,
      );
    },
    [cache],
  );

  const getCachedRecordsById = React.useCallback(
    (type, ids, attributes, include) => {
      return getResultFromCacheAccordingToAttributesAndInclude(
        ids.map((id) => ({
          type: recordCacheIdType,
          id: `${type}:${id}`,
        })),
        cache.denormalizeRecord.bind(cache),
        {
          options: {
            attributes,
            include,
          },
        },
      ).filter(Boolean);
    },
    [cache],
  );

  const normalizeResult = React.useCallback(
    (result) => {
      return cache.normalizeResult(result);
    },
    [cache],
  );

  const mergeAndNormalizeResult = React.useCallback(
    ({ method, params }, prevNormalizedResult, newResult) => {
      const prevResult = denormalizeResult(
        { method, params },
        prevNormalizedResult,
      );
      const finalResult =
        prevResult && method.mergeResult
          ? method.mergeResult(prevResult, newResult)
          : newResult;
      const res = normalizeResult(finalResult);

      // optimization: return same normalizedResult if result did not change so
      // that it won't trigger a state update
      if (finalResult === prevResult) {
        return {
          ...res,
          normalizedResult: prevNormalizedResult,
        };
      }
      return res;
    },
    [denormalizeResult, normalizeResult],
  );

  const watchResult = React.useCallback(
    ({ normalizedResult, callback }) => {
      const recordsToWatch = [];
      const traverse = (value) => {
        if (Array.isArray(value)) {
          value.forEach(traverse);
        } else if (typeof value !== 'object' || value === null) {
        } else if (value.type === recordCacheIdType) {
          recordsToWatch.push(value);
        } else {
          Object.values(value).forEach(traverse);
        }
      };
      traverse(normalizedResult);
      return cache.watchRecords({
        normalizedRecords: recordsToWatch,
        callback,
      });
    },
    [cache],
  );

  const apiClient = React.useMemo(
    () => ({
      cache,
      query,
      mutate,
      subscribe,
      denormalizeResult,
      getCachedRecordsById,
      mergeAndNormalizeResult,
      normalizeResult,
      watchResult,
    }),
    [
      cache,
      denormalizeResult,
      getCachedRecordsById,
      mergeAndNormalizeResult,
      mutate,
      normalizeResult,
      query,
      subscribe,
      watchResult,
    ],
  );

  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
}

function useCache(username) {
  // save username as ref for comparison
  const usernameRef = React.useRef(username);
  React.useEffect(() => {
    usernameRef.current = username;
  });

  const cacheRef = React.useRef();
  if (
    !cacheRef.current ||
    // recreate cache if user has changed
    usernameRef.current !== username
  ) {
    cacheRef.current = new Cache();
  }

  return cacheRef.current;
}

export function useApiClient() {
  const apiClient = useContext(ApiClientContext);
  return apiClient;
}
