import moment from 'moment';
import validator from 'validator';

import { SystemDateFormats } from 'src/constants/DateFormats';
import { ObjectField, ObjectFieldTypes } from 'src/types/object';

import { DistributivePick } from './TypeUtils';

type Validator = (value: string | null | undefined) => string | undefined;

/**
 * Phone number validation
 * prefix: allow 2 country/area segments - 1-5 digits, can contain +, space or ()
 * main number can be concatenated with '-' and space, length ranges between 1-20
 * must end with a numeric value
 */
const phoneRegex = /^\s*[+]?[(]?[+]?[0-9\s]{1,5}[)]?\s?[(]?[0-9\s]{1,5}[)]?\s?[-\s0-9]{1,20}[0-9]+\s*$/i;

/**
 * Checks if a value is undefined, null or empty string
 */
const notEmpty = (value: string | null | undefined): value is string => {
  return value != null && value !== '';
};

/**
 * Validator that always passes.
 * `undefined` means no error message.
 */
export const pass = () => undefined;

export const required = ({ msg = 'Required' } = {}): Validator => {
  return (value) => {
    if (!notEmpty(value)) {
      return msg;
    }
    return pass();
  };
};

export const isNotIn = ({
  msg = 'Not allowed',
  values,
  ignoreCase = false,
}: {
  msg?: string;
  values?: string[];
  ignoreCase?: boolean;
} = {}): Validator => {
  if (!values || values.length === 0) return pass;
  return (value) => {
    if (!notEmpty(value)) return pass();

    if (ignoreCase) {
      const valueUpperCase = value.trim().toUpperCase();
      if (values.some((v) => v.toUpperCase() === valueUpperCase)) {
        return msg;
      }
    } else {
      if (values.includes(value.trim())) {
        return msg;
      }
    }
    return pass();
  };
};

export const isAlphanumeric = ({
  msg = 'Must contain only letters and numbers',
} = {}): Validator => {
  return (value) => {
    if (notEmpty(value) && !validator.isAlphanumeric(value)) {
      return msg;
    }
    return pass();
  };
};

export const isEmail = ({ msg = 'Must be a valid email' } = {}): Validator => {
  return (value) => {
    const trimmedValue = typeof value === 'string' ? value.trim() : null;
    if (notEmpty(trimmedValue) && !validator.isEmail(trimmedValue)) {
      return msg;
    }
    return pass();
  };
};

const getTextLengthErrorMessage = (
  min: number | undefined,
  max: number | undefined,
) => {
  if (min !== undefined && max !== undefined) {
    return `Length must be between ${min} and ${max}`;
  } else if (min !== undefined) {
    return `Must be longer than or equal to ${min} characters`;
  } else if (max !== undefined) {
    return `Must be shorter than or equal to ${max} characters`;
  }
  return undefined;
};

export const isTextLength = ({
  msg,
  min,
  max,
}: {
  msg?: string;
  min?: number;
  max?: number;
} = {}): Validator => {
  const errMsg = msg || getTextLengthErrorMessage(min, max);
  return (value) => {
    if (notEmpty(value) && !validator.isLength(value, { min, max })) {
      return errMsg;
    }
    return pass();
  };
};

export const isWhitelistedCharacter = ({
  msg,
  chars,
}: {
  msg?: string;
  chars?: string[];
} = {}): Validator => {
  if (!chars || chars.length === 0) return pass;
  const errMsg = msg || `Must contain only the following characters: ${chars}`;
  return (value) => {
    if (notEmpty(value) && !validator.isWhitelisted(value, chars)) {
      return errMsg;
    }
    return pass();
  };
};

const getNumberRangeErrorMessage = (
  min: number | undefined,
  max: number | undefined,
) => {
  if (min !== undefined && max !== undefined) {
    return `Must be between ${min} and ${max}`;
  } else if (min !== undefined) {
    return `Must be larger than or equal to ${min}`;
  } else if (max !== undefined) {
    return `Must be less than or equal to ${max}`;
  }
  return 'Must be a number';
};

export const isBetweenInteger = ({
  msg,
  min,
  max,
}: {
  msg?: string;
  min?: number;
  max?: number;
} = {}): Validator => {
  const errMsg = msg || getNumberRangeErrorMessage(min, max);
  return (value) => {
    const valueStr = `${value}`;
    if (notEmpty(value) && !validator.isInt(valueStr, { min, max })) {
      return errMsg;
    }
    return pass();
  };
};

export const isBetweenNumber = ({
  msg,
  min,
  max,
}: {
  msg?: string;
  min?: number;
  max?: number;
} = {}): Validator => {
  const errMsg = msg || getNumberRangeErrorMessage(min, max);
  return (value) => {
    const valueStr = `${value}`;
    if (notEmpty(value) && !validator.isFloat(valueStr, { min, max })) {
      return errMsg;
    }
    return pass();
  };
};

export const isInt = ({ msg = 'Must be an integer' } = {}): Validator => {
  return (value) => {
    const valueStr = `${value}`;
    if (notEmpty(value) && !validator.isInt(valueStr)) {
      return msg;
    }
    return pass();
  };
};

export const matches = ({
  msg,
  pattern,
}: {
  msg: string;
  pattern: RegExp;
}): Validator => {
  return (value) => {
    if (notEmpty(value) && !validator.matches(value, pattern)) {
      return msg;
    }
    return pass();
  };
};

const createValidator = (
  validate: (value: string) => boolean,
  msg: string,
): Validator => {
  return (value) => {
    if (value == null) return;
    if (!validate(value)) {
      return `${msg} "${value}"`;
    }
  };
};

export const createTypeValidator = (
  fieldDefinition: DistributivePick<ObjectField, 'type' | 'properties'>,
) => {
  const defaultMsg = `Invalid ${fieldDefinition.type} value`;
  switch (fieldDefinition.type) {
    case ObjectFieldTypes.CHECKBOX:
      return createValidator(
        (value) => String(value) === 'true' || String(value) === 'false',
        defaultMsg,
      );
    case ObjectFieldTypes.CURRENCY:
      return createValidator(
        (value) => validator.isDecimal(String(value).trim()),
        defaultMsg,
      );
    case ObjectFieldTypes.DATE: {
      const format =
        fieldDefinition.properties.format || SystemDateFormats.DATE;
      return createValidator(
        (value) => moment(value, format, true).isValid(),
        defaultMsg,
      );
    }
    case ObjectFieldTypes.EMAIL:
      return createValidator(
        (value) => validator.isEmail(String(value).trim()),
        defaultMsg,
      );
    case ObjectFieldTypes.FIXED_LIST:
      return createValidator(
        (value) =>
          fieldDefinition.properties.options.some(
            (option) => option.id === String(value),
          ),
        defaultMsg,
      );
    case ObjectFieldTypes.MONTH:
      return createValidator(
        (value) => validator.isInt(String(value), { min: 1, max: 12 }),
        defaultMsg,
      );
    case ObjectFieldTypes.PHONENUMBER:
      return createValidator(
        (value) => phoneRegex.test(String(value).trim()),
        defaultMsg,
      );
    case ObjectFieldTypes.QUANTITY: {
      const decimalPlaces = fieldDefinition.properties.decimalPlaces || 0;
      return createValidator(
        (value) =>
          value !== '.' && // validator.isDecimal thinks it's ok
          validator.isDecimal(String(value).trim(), {
            decimal_digits: `0,${String(decimalPlaces)}`,
          }),
        defaultMsg,
      );
    }
    case ObjectFieldTypes.TIME: {
      const format =
        fieldDefinition.properties.format || SystemDateFormats.TIME;
      return createValidator(
        (value) => moment(value, format, true).isValid(),
        defaultMsg,
      );
    }
    case ObjectFieldTypes.YEAR:
      return createValidator(
        (value) =>
          validator.isInt(String(value).trim(), { min: 1000, max: 9999 }),
        'Year range is between 1000 - 9999. Your value: ',
      );
    default:
      return null;
  }
};

export const createFieldValidator = (
  fieldDefinition: ObjectField,
): Validator => {
  const { type, required: fieldIsRequired } = fieldDefinition;

  // Auto increment fields don't need to be validated
  if (type === ObjectFieldTypes.AUTOINCREMENT) return pass;

  const validators: Validator[] = [];

  // required validation. This must be the first validator
  if (fieldIsRequired) {
    validators.push(required());
  }

  // field type validations
  const typeValidator = createTypeValidator(fieldDefinition);
  if (typeValidator) {
    validators.push(typeValidator);
  }

  return (value) => {
    // if field is not required and the value is null/undefined/empty string,
    // skip all the validations
    if (!fieldIsRequired && !notEmpty(value)) {
      return;
    }
    // otherwise run through the validators until any one returns an error
    return validators.reduce((result: string | undefined, validator) => {
      if (result) return result;
      return validator(value);
    }, undefined);
  };
};

export const LabelSpacesValidator = [
  // matches({
  //   msg: 'Trailing spaces not allowed',
  //   pattern: /\S$/,
  // }),
  matches({
    msg: 'First character cannot be a space',
    pattern: /^\S/,
  }),
];

export const LabelValidatorMatches = [
  matches({
    msg: 'Only letters, numbers, brackets, dashes and underscores are allowed',
    pattern: /^[A-Za-z0-9 ()\-_]+$/,
  }),
  ...LabelSpacesValidator,
];
