// https://github.com/josdejong/mathjs/blob/8fcf7d22cfe0c0e6762c7009cb50e004779e6796/src/utils/number.js

/**
 * Format a number with fixed notation.
 */
export function toFixed(value: number | string, precision?: number) {
  if (isNaN(value as any) || !isFinite(value as any)) {
    return '';
  }

  const splitValue = splitNumber(value);
  const rounded =
    typeof precision === 'number'
      ? roundDigits(splitValue, splitValue.exponent + 1 + precision)
      : splitValue;
  let c: (string | number)[] = rounded.coefficients;
  let p = rounded.exponent + 1; // exponent may have changed

  // append zeros if needed
  const pp = p;
  if (c.length < pp) {
    c = c.concat(zeros(pp - c.length));
  }

  // prepend zeros if needed
  if (p < 0) {
    c = zeros(-p + 1).concat(c);
    p = 1;
  }

  // insert a dot if needed
  if (p < c.length) {
    c.splice(p, 0, p === 0 ? '0.' : '.');
  }

  return rounded.sign + c.join('');
}

/**
 * Round the number of digits of a number *
 */
export function roundDigits(split: SplitValue, precision: number) {
  // create a clone
  const rounded = {
    sign: split.sign,
    coefficients: split.coefficients,
    exponent: split.exponent,
  };
  const c = rounded.coefficients;

  // prepend zeros if needed
  while (precision <= 0) {
    c.unshift(0);
    rounded.exponent++;
    precision++;
  }

  if (c.length > precision) {
    const removed = c.splice(precision, c.length - precision);

    if (removed[0] >= 5) {
      let i = precision - 1;
      c[i]++;
      while (c[i] === 10) {
        c.pop();
        if (i === 0) {
          c.unshift(0);
          rounded.exponent++;
          i++;
        }
        i--;
        c[i]++;
      }
    }
  }

  return rounded;
}

type SplitValue = {
  sign: string;
  coefficients: number[];
  exponent: number;
};

/**
 * Split a number into sign, coefficients, and exponent
 */
export function splitNumber(value: number | string): SplitValue {
  // parse the input value
  const match = String(value)
    .toLowerCase()
    .match(/^0*?(-?)(\d*\.?\d*)(e([+-]?\d+))?$/);
  if (!match) {
    throw new SyntaxError('Invalid number ' + value);
  }

  const sign = match[1];
  const digits = match[2].startsWith('.') ? `0${match[2]}` : match[2];
  let exponent = parseFloat(match[4] || '0');

  const dot = digits.indexOf('.');
  exponent += dot !== -1 ? dot - 1 : digits.length - 1;

  const coefficients = digits
    .replace('.', '') // remove the dot (must be removed before removing leading zeros)
    .replace(/^0*/, function(zeros) {
      // remove leading zeros, add their count to the exponent
      exponent -= zeros.length;
      return '';
    })
    .replace(/0*$/, '') // remove trailing zeros
    .split('')
    .map(function(d) {
      return parseInt(d);
    });

  if (coefficients.length === 0) {
    coefficients.push(0);
    exponent++;
  }

  return {
    sign: sign,
    coefficients: coefficients,
    exponent: exponent,
  };
}

/**
 * Create an array filled with zeros.
 */
export function zeros(length: number): (string | number)[] {
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push(0);
  }
  return arr;
}
