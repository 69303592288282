import React from 'react';

import { Icon, TimePicker } from 'src/components/bappo-components/src';
import { TimePickerProps } from 'src/components/bappo-components/src/components/TimePicker/types';
import { ObjectFieldTypes, TimeField } from 'src/types/object';

import { createField } from './createField';

export type TimeInputProps = Omit<
  TimePickerProps,
  'displayFormat' | 'renderDropdownIcon'
> & {
  properties: TimeField['properties'];
};

const renderDropdownIcon = () => <Icon name="calendar-today" />;

export const TimeInput = React.forwardRef(function TimeInput(
  { properties, ...rest }: TimeInputProps,
  ref: React.Ref<TimePicker>,
) {
  const { format } = properties;

  return (
    <TimePicker
      {...rest}
      displayFormat={format || 'h:mm A'}
      renderDropdownIcon={renderDropdownIcon}
      ref={ref}
      modal
    />
  );
});

export const TimeInputField = createField(ObjectFieldTypes.TIME, TimeInput);
