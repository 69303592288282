export type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

/**
 * Make only keys K partial
 */
export type DistributivePartial<T, K extends keyof any> = T extends any
  ? Omit<T, K> & Partial<Pick<T, keyof T & K>>
  : never;

type DistributiveKeys<T> = T extends any ? keyof T : never;
export type DistributivePick<T, K extends DistributiveKeys<T>> = T extends any
  ? Pick<T, Extract<K, keyof T>>
  : never;

export function impossible(value: never): never {
  console.error('impossible value', value);
  throw new Error();
}

export function isNonNullable<T>(
  value: T | null | undefined | false,
): value is T {
  return value != null && value !== false;
}
