// https://github.com/softonic/axios-retry/blob/v3.1.8/es/index.js
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { isIdempotentRequestError } from 'axios-retry';
import isRetryAllowed from 'is-retry-allowed';

const namespace = 'navlab-axios-retry';

export const networkErrorMsg =
  'Oops\nThe server seems to be unreachable at this moment.\nPlease check your internet connection.';

export function isNetworkError(error: AxiosError) {
  return (
    !error.response &&
    (error.message === 'Network Error' || Boolean(error.code)) &&
    error.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
    isRetryAllowed(error) // Prevents retrying unsafe errors
  );
}

export function isNetworkOrIdempotentRequestError(error: AxiosError) {
  return isNetworkError(error) || isIdempotentRequestError(error);
}

export function exponentialDelay(retryNumber = 0) {
  const delay = Math.pow(2, retryNumber) * 1000;
  const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
  return delay + randomSum;
}

/**
 * Initializes and returns the retry state for the given request/config
 */
export function getCurrentState(config: any) {
  const currentState = config[namespace] || {};
  currentState.retryCount = currentState.retryCount || 0;
  config[namespace] = currentState;
  return currentState;
}

export function fixConfig(
  axiosInstance: AxiosInstance,
  config: AxiosRequestConfig,
) {
  // if (axios.defaults.agent === config.agent) {
  //   delete config.agent;
  // }
  if (axiosInstance.defaults.httpAgent === config.httpAgent) {
    delete config.httpAgent;
  }
  if (axiosInstance.defaults.httpsAgent === config.httpsAgent) {
    delete config.httpsAgent;
  }
}
