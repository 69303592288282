import React from 'react';

import { TextInput } from 'src/components/bappo-components/src';
import { TextInputProps } from 'src/components/bappo-components/src/primitives/TextInput/types';
import { ObjectFieldTypes, PhoneNumberField } from 'src/types/object';

import { createField } from './createField';

export type PhoneNumberInputProps = Omit<
  TextInputProps,
  'multiline' | 'type'
> & {
  properties: PhoneNumberField['properties'];
};

export const PhoneNumberInput = React.forwardRef(function PhoneNumberInput(
  { properties, value, ...rest }: PhoneNumberInputProps,
  ref: React.Ref<TextInput>,
) {
  return (
    <TextInput
      {...rest}
      multiline={false}
      type="tel"
      value={value || ''}
      ref={ref}
    />
  );
});

export const PhoneNumberInputField = createField(
  ObjectFieldTypes.PHONENUMBER,
  PhoneNumberInput,
);
