import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { theme } from 'src/style/themes';
import styled from 'styled-components';

import Router from './Router';
import Spinner from './components/BaseComponents/Spinner';
import {
  DeviceKindProvider,
  Font,
  getDeviceKindByWidth,
} from './components/bappo-components/src';

const currentTheme = theme;

const muitheme = createTheme({
  typography: {
    fontFamily: `"Proxima Nova", "Helvetica", "Arial", sans-serif`
  },
  // palette: {
  //   mode: 'light',
  //   // primary: {
  //   //   main: '#82c44c',
  //   // },
  //   // secondary: {
  //   //   main: '#00796b',
  //   // },
  // },
  // components: {
  //   MuiButton: {
  //     defaultProps: {
  //       disableElevation: true,
  //     },
  //     styleOverrides: {
  //       outlinedPrimary: {
  //         textTransform: 'none',
  //         backgroundColor: 'transparent',
  //         color: '#0070D2',
  //         borderColor: '#DDDBDA',
  //         '&:hover': {
  //           backgroundColor: '#F2F1F1',
  //           borderColor: '#F2F1F1',
  //           color: '#82c44c',
  //         },
  //         // "&.Mui-focused": {
  //         //   backgroundColor: "red",
  //         // },
  //         // "&.focused": {
  //         //   backgroundColor: "red",
  //         // },
  //         // "&.focus": {
  //         //   backgroundColor: "red",
  //         // },
  //         // ".Mui-active": {
  //         //   backgroundColor: "red",
  //         // },
  //         // ".Mui-focusVisible": {
  //         //   backgroundColor: "red",
  //         //   color: "red",
  //         // },
  //         // '&.active': {
  //         //   //backgroundColor:'#0070D2',
  //         //   backgroundColor: 'red',
  //         // },
  //       },
  //       containedPrimary: {
  //         textTransform: 'none',
  //         backgroundColor: '#82c44c',
  //         color: 'white',
  //         borderColor: '#82c44c',
  //         '&:hover': {
  //           backgroundColor: '#9cd071',
  //           borderColor: '#9cd071',
  //         },
  //       },
  //     },
  //   },
  // }
});

const Root = () => {
  return (
    <DeviceKindProvider value={getDeviceKindByWidth(window.innerWidth)}>
      <Font fontFamily="Proxima Nova">
        <MuiThemeProvider theme={muitheme}>
          <ThemeProvider theme={currentTheme}>
            <React.Suspense fallback={<Spinner />}>
              <Sentry.ErrorBoundary showDialog fallback={<ErrorContainer>Oops, something went wrong. Please refresh the page.</ErrorContainer>}>
                <Router />
              </Sentry.ErrorBoundary>
            </React.Suspense>
          </ThemeProvider>
        </MuiThemeProvider>
      </Font>
    </DeviceKindProvider>
  );
};

export default Root;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;