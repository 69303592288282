import styled from 'styled-components';
import logo from 'src/assets/images/navlab_logo.png';

export default styled.div`
  flex: none;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: 200px auto;
  background-position: left;
  cursor: pointer;
  height: 200px;
`;
