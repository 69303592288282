import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, AxiosError } from 'axios';
import qs from 'qs';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';

import { Alert } from 'src/components/bappo-components/src';

import {
  exponentialDelay,
  fixConfig,
  getCurrentState,
  isNetworkOrIdempotentRequestError,
  networkErrorMsg,
} from './axios-helpers';

import * as Sentry from '@sentry/react';

type TenantManagementPermissionLevel = 'owner' | 'admin' | 'user';
type CognitoUserInfo = {
  id: string;
  username: string;
  attributes: Record<string, any>;
};
type TenantUser = {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  avatarColour: string;
  _tenant: {
    name: string;
    logo: string;
    _extra: any;
  };
  roles: string[];
  tenantManagementPermissionLevel: TenantManagementPermissionLevel;
};

type AuthenticatedRequesterContextValue = {
  currentUser: TenantUser;
  getRequester: () => AxiosInstance;
  logout: () => Promise<any>;
};
const AuthenticatedRequesterContext = createContext<
  AuthenticatedRequesterContextValue | undefined
>(undefined);

/**
 * This component:
 * - Exposes a function to call APIs as an authenticated user through React
 *   context.
 * - Exposes current user details through React context.
 * - Redirect to the login screen if user is not authenticated.
 * - Protects user from sending a requester as a wrong user when they have
 *   multiple tabs of different user sessions open.
 */
export function AuthenticatedRequester({
  children,
}: {
  children: React.ReactNode;
}) {
  const axiosInsRef = useRef<AxiosInstance | null>(null);
  const [checked, setChecked] = useState(false);
  // user info in Cognito
  const [
    currentUserInfo,
    setCurrentUserInfo,
  ] = useState<CognitoUserInfo | null>(null);
  const [currentTenantUser, setCurrentTenantUser] = useState<TenantUser | null>(
    null,
  );

  // Initialize currentUserInfo
  useEffect(() => {
    let canceled = false;
    async function initCurrentUserInfo() {
      try {
        const currentUserInfo = await Auth.currentUserInfo();

        if (canceled) return;
        // Amplify can possibly return an empty object
        // https://github.com/aws-amplify/amplify-js/blob/aws-amplify%403.3.25/packages/auth/src/Auth.ts#L1862
        const sanitizedCurrentUserInfo =
          currentUserInfo && currentUserInfo.id && currentUserInfo.username
            ? {
                ...currentUserInfo,
                name: `${currentUserInfo.attributes?.given_name} ${currentUserInfo.attributes?.family_name}`,
              }
            : null;
        setCurrentUserInfo(sanitizedCurrentUserInfo);
      } finally {
        setChecked(true);
      }
    }
    initCurrentUserInfo();
    return () => {
      canceled = true;
    };
  }, []);

  useEffect(() => {
    if(currentUserInfo) {
      try {
        Sentry.setContext("user", {
          given_name: currentUserInfo?.attributes?.given_name,
          family_name: currentUserInfo?.attributes?.family_name,
          email: currentUserInfo?.attributes?.email,
          tenant: currentUserInfo?.attributes ? currentUserInfo?.attributes["custom:_tenant"] : '',
        });
      } catch(err) {
        console.log(err);
      }
    }
  }, [currentUserInfo])

  // TODO: watch login and logout

  // Configure axios instance
  const [prevCurrentUserInfo, setPrevCurrentUserInfo] = useState(
    currentUserInfo,
  );
  if (currentUserInfo && currentUserInfo !== prevCurrentUserInfo) {
    const axiosIns = axios.create();

    axiosIns.defaults.paramsSerializer = (params) => qs.stringify(params);
    axiosIns.interceptors.request.use(
      async function requestInterceptor(config) {
        // check token
        let jwt;
        try {
          const currentSession = await Auth.currentSession();
          jwt = currentSession.getIdToken().getJwtToken();
        } catch {}
        if (!jwt) {
          await Alert.alert({
            actions: {
              confirm: {
                text: 'Reload',
              },
            },
            message: `You have been logged out. Please click on "Reload" to log in to Navlab again.`,
            title: `Please log in again`,
          });
          window.location.reload();
          return Promise.reject(new Error(`Unauthorized`));
        }
        // add token to header authorization
        config.headers.Authorization = jwt;

        // TODO: resolve API host
        config.baseURL = `https://api.${window.location.host.replace(/^(www\.)/,"")}`;
        // config.baseURL = `http://localhost:4000`;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    axiosIns.interceptors.response.use(
      (response) => {
        return response;
      },
      function responseErrorInterceptor(error: AxiosError) {
        const MAX_RETRY = 5;
        const config = error.config;

        // retry
        if (config) {
          const currentState = getCurrentState(config);

          const shouldRetry =
            isNetworkOrIdempotentRequestError(error) &&
            currentState.retryCount < MAX_RETRY;

          if (shouldRetry) {
            currentState.retryCount += 1;
            const delay = exponentialDelay(currentState.retryCount);

            // Axios fails merging this configuration to the default configuration because it has an issue
            // with circular structures: https://github.com/mzabriskie/axios/issues/370
            fixConfig(axiosIns, config);

            if (config.timeout && currentState.lastRequestTime) {
              const lastRequestDuration =
                Date.now() - currentState.lastRequestTime;
              // Minimum 1ms timeout (passing 0 or less to XHR means no timeout)
              config.timeout = Math.max(
                config.timeout - lastRequestDuration - delay,
                1,
              );
            }

            config.transformRequest = [(data: any) => data];

            return new Promise((resolve) =>
              setTimeout(() => resolve(axiosIns(config)), delay),
            );
          }
        }

        if (error.response?.status) {
          // server error
          if (error.response.status >= 500) {
            Alert.alert(`Oops, something went wrong. Please try again later.`);
          }
        } else if (error.message === 'Network Error') {
          Alert.alert(networkErrorMsg);
        } else {
          // client error, probably a bug
          if (typeof window !== 'undefined' && (window as any).Sentry) {
            (window as any).Sentry.captureException(error);
          }
          Alert.alert(`Oops, something went wrong. Please refresh the page.`);
        }

        return Promise.reject(error);
      },
    );

    axiosInsRef.current = axiosIns;

    // fetch current tenant user
    axiosIns.get('/api/v0/me').then(({ data }) => setCurrentTenantUser(data));
  } else if (!currentUserInfo) {
    axiosInsRef.current = null;
  }
  if (currentUserInfo !== prevCurrentUserInfo) {
    setPrevCurrentUserInfo(currentUserInfo);
  }

  const location = useLocation();

  const contextValue = useMemo(() => {
    return {
      currentUser: currentTenantUser,
      // Requester must have a value. Otherwise the user should have been
      // redirected to the login page.
      getRequester: () => axiosInsRef.current!,
      logout: async () => {
        await Auth.signOut();
        location.pathname = '/';
        setCurrentUserInfo(null);
      },
    };
  }, [currentTenantUser]);

  // Redirect to login page if not authenticated
  const history = useHistory();
  useEffect(() => {
    if (checked && !currentUserInfo) {
      history.push(`/login`, { from: location?.pathname ?? '/' });
    }
  }, [checked, currentUserInfo, history]);

  if (!checked) {
    // Show a blank screen while we check initial auth status.
    return null;
  }

  if (!currentTenantUser) {
    // Show a blank screen if not authenticated
    return null;
  }

  return (
    <AuthenticatedRequesterContext.Provider
      value={contextValue as AuthenticatedRequesterContextValue}
    >
      {children}
    </AuthenticatedRequesterContext.Provider>
  );
}

export function useAuthenticatedRequester() {
  const { getRequester } = useContext(AuthenticatedRequesterContext)!;
  return getRequester;
}

export function useCurrentUser() {
  const { currentUser } = useContext(AuthenticatedRequesterContext)!;
  return currentUser;
}
export function useLogout() {
  const { logout } = useContext(AuthenticatedRequesterContext)!;
  return logout;
}
export function useIsCurrentUserAuthorised(roles: string[]) {
  const { currentUser } = useContext(AuthenticatedRequesterContext)!;

  try {
    return (
      currentUser?.roles?.filter((role) =>
        roles.map((role) => role.toLowerCase()).includes(role.toLowerCase()),
      ).length > 0 ?? false
    );
  }
  catch(ex){
    console.log('An error occurred while trying to authorise user roles: ', ex);
    return false;
  }
}
