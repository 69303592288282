import { required, isEmail, isTextLength } from 'src/utils/ValidationUtils';

export const emailValidators = [
  required({
    msg: 'Email is required',
  }),
  isEmail(),
];

export const passwordValidators = [
  required({
    msg: 'Password is required',
  }),
  isTextLength({
    msg: 'Password needs to be at least 8 characters',
    min: 8,
  })
];

export const verificationCodeValidators = [required()];
