import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import styled from 'styled-components';
import View from '../../../primitives/View';
import Text from '../../../primitives/Text';
import TextInput from '../../../primitives/TextInput';
import ScrollView from '../../../primitives/ScrollView';
import { base64StringToBlob } from 'blob-util';
import { ScrollEvent } from '../../../events';
import _, { isNumber, toNumber } from 'lodash';

import './styles.css';
import IconButton from '../../../components/IconButton';
import Spinner from 'src/components/BaseComponents/Spinner';

const DEFAULT_SCALE_DELTA = 0.1;
const MIN_SCALE = 0.1;
const MAX_SCALE = 10.0;

export default function PdfViewer({src, fileName}) {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const [pages, setPages] = useState<Array<any>>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPages(new Array(numPages));
    setIsLoading(false);
  }

  function increaseScale(steps = 1) {
    let newScale = scale;

    do {
      newScale = (newScale + DEFAULT_SCALE_DELTA).toFixed(2);
      //newScale = Math.ceil(newScale * 10) / 10;
      newScale = Math.min(MAX_SCALE, newScale);
    } while (--steps > 0 && newScale < MAX_SCALE);

    setScale(newScale);

    return true;
  }

  function decreaseScale(steps = 1) {
    let newScale = scale;

    do {
      newScale = (newScale - DEFAULT_SCALE_DELTA).toFixed(2);
      //newScale = Math.floor(newScale * 10) / 10;
      newScale = Math.max(MIN_SCALE, newScale);
    } while (--steps > 0 && newScale > MIN_SCALE);

    setScale(newScale);

    return true;
  }

  function downloadBase64() {
    if(src) {
      const base64src = src.replace(/^data:application\/pdf;base64,/,'',);

      const blobUrl = URL.createObjectURL(base64StringToBlob(base64src, "application/pdf"));

      download(blobUrl, fileName);
    }
  }

  function download(blobUrl, filename) {
    const a = document.createElement("a");
    if (!a.click) {
      throw new Error('DownloadManager: "a.click()" is not supported.');
    }
    a.href = blobUrl;
    a.target = "_parent";
    // Use a.download if available. This increases the likelihood that
    // the file is downloaded instead of opened by another PDF plugin.
    if ("download" in a) {
      a.download = filename;
    }
    // <a> must be in the document for recent Firefox versions,
    // otherwise .click() is ignored.
    (document.body || document.documentElement).append(a);
    a.click();
    a.remove();
  }

  const registerPage = (pageIndex, ref) => {
    setPages((pages) => { const newPages = Array.from(pages); newPages[pageIndex] = ref; return newPages; });
  };

  const unregisterPage = (pageIndex) => {
    setPages((pages) => { const newPages = Array.from(pages); delete newPages[pageIndex]; return newPages; });
  };

  const onScroll = (event: ScrollEvent) => {
    try {
      const center = event.nativeEvent.contentOffset.y + (event.nativeEvent.layoutMeasurement.height / 2);
      
      let start = 0, end = 0;
      let currentpage = 1;

      for(const page of pages) {
        if(page) {
          end = end + page.clientHeight;

          if(start < center && end > center) {
            setCurrentPage(currentpage);
            setSelectedPage(currentpage);
            return;
          }
          currentpage++;
          start = end;
        }
      }
    } catch(ex) {
      console.log(ex);
    }
  };

  const onPageNumberChange = () => {
    const _selectedPage = toNumber(selectedPage);

    if(isNumber(_selectedPage) && _selectedPage !== currentPage) {
      if(_selectedPage > 0 && _selectedPage <= numPages) {
        if(selectedPage !== currentPage) {
          setCurrentPage(_selectedPage);
          pages[selectedPage - 1]?.scrollIntoView();
        }
      } else {
        setSelectedPage(currentPage);
      }
    }
  }

  return (
    <Container>
      <Toolbar>
        { isLoading ? <Spinner /> :
        <>
          <ToolbarIcon name='zoom-out' onPress={() => decreaseScale()} />
          <Text style={{ width: "50px", padding: "2px", margin: "auto 0", backgroundColor: "white", textAlign: "center" }}>{ Math.trunc(scale * 100) }%</Text>
          <ToolbarIcon name='zoom-in' onPress={() => increaseScale()} />
          <div style={{ margin: "auto" }}>
          <TextInput style={{ backgroundColor: "white", padding: "2px", marginRight: "2px", width: "20px" }} type="number" onBlur={onPageNumberChange} onValueChange={setSelectedPage} value={selectedPage}></TextInput><Text style={{ width: "50px", padding: "2px", paddingLeft: "0px", textAlign: "center", flex: "1" }}>{ `/ ${numPages}` }</Text>
          </div>
          <ToolbarIcon style={{ marginLeft: "auto" }} name='file-download' onPress={() => downloadBase64()} />
        </>
        }
      </Toolbar>
      <ScrollContainer onScroll={_.debounce(onScroll, 100)}>
        <center>
          <Document
            file={src}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(
              new Array(numPages),
              (el, index) => (
                <Page
                  registerPage={registerPage}
                  unregisterPage={unregisterPage}
                  scale={scale}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ),
            )}
          </Document>
        </center>
      </ScrollContainer>
    </Container>
  );
}

const Container = styled(View)`
  position: relative;
  flex: 1;
  background-color: gray;
`;

const ScrollContainer = styled(ScrollView)`
  flex: 1;
  overflow: auto;
  margin-top: 40px;
`;

const ToolbarIcon = styled(IconButton).attrs({
  size: 24,
})`
  margin: auto 0;
`;

export const Toolbar = styled(View)`
  position: absolute;
  flex-direction: row;
  height: 40px;
  padding: 4px;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: lightgray;
`;