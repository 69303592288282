import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default styled(Link)`
  display: flex;
  min-height: 0;
  text-decoration: none;

  * {
    cursor: inherit;
  }
`;
