import _ from 'lodash';

import { recordCacheIdType } from './helpers';

class InMemoryCache {
  constructor() {
    this.recordCache = {};
    this.watches = [];
  }

  /**
   * Clear all cached data and remove listeners
   */
  clear() {
    this.watches = [];
    this.invalidate({
      shouldInvalidateRecord: () => true,
    });
  }

  denormalizeRecord(normalizedRecord) {
    if (!normalizedRecord) return normalizedRecord;
    return this.recordCache[normalizedRecord.id];
  }

  /**
   * Get cached records by id
   */
  getRecords({ formatResult, recordIds = [] }) {
    return formatResult(
      recordIds.map((id) => ({
        type: recordCacheIdType,
        id,
      })),
      (id) => this.recordCache[id],
    );
  }

  /**
   * Invalidate cache
   */
  invalidate({ shouldInvalidateRecord }) {
    const invalidatedRecordCacheIds = Object.keys(this.recordCache).filter(
      shouldInvalidateRecord,
    );
    const watchesToCall = [];
    this.watches.forEach((watchOptions) => {
      // notify query listener if records in query result were updated
      for (const recordCacheId of invalidatedRecordCacheIds) {
        if (watchOptions.recordCacheIds.has(recordCacheId)) {
          watchesToCall.push(watchOptions);
          return;
        }
      }
    });
    watchesToCall.forEach(({ recordCacheIds, callback, formatResult }) => {
      callback({
        type: 'cache:invalidate',
      });
    });
  }

  normalizeResult(result) {
    const updatedRecords = {};

    const normalize = (value) => {
      if (Array.isArray(value)) {
        return value.map(normalize);
      }
      if (typeof value === 'object' && value !== null) {
        if (value.__typename) {
          // value is a record
          const recordCacheId = `${value.__typename}:${value.id}`;
          // shallow merge with cached record
          updatedRecords[recordCacheId] = {
            ...(updatedRecords[recordCacheId] ||
              this.recordCache[recordCacheId] ||
              {}),
            ..._.mapValues(value, normalize),
          };
          // return cache id instead of the entire record
          return {
            type: recordCacheIdType,
            id: recordCacheId,
          };
        }
        return _.mapValues(value, normalize);
      }
      return value;
    };

    // recursively normalize result
    const normalizedResult = normalize(result);

    return {
      normalizedResult,
      records: updatedRecords,
    };
  }

  /**
   * Watch cached records by id
   */
  watchRecords({ callback, normalizedRecords }) {
    if (normalizedRecords.length === 0) {
      return () => {};
    }

    const watchOptions = {
      callback,
      recordCacheIds: new Set(normalizedRecords.map((rec) => rec.id)),
    };
    this.watches.push(watchOptions);

    return () => {
      this.watches = this.watches.filter((options) => options !== watchOptions);
    };
  }

  /**
   * Write records into cache
   */
  writeRecords(data) {
    if (!data) return;

    const { records } = this.normalizeResult(data);

    // save updated records to cache
    Object.entries(records).forEach(([recordCacheId, updatedRecord]) => {
      if (_.isEqual(updatedRecord, this.recordCache[recordCacheId])) {
        // record did not change
        delete records[recordCacheId];
      } else {
        this.recordCache[recordCacheId] = updatedRecord;
      }
    });

    // broadcast to query listeners
    this.broadcastRecordUpdates(records);
  }

  /**
   * @private
   */
  broadcastRecordUpdates(updatedRecords) {
    const updatedRecordCacheIds = Object.keys(updatedRecords);
    if (updatedRecordCacheIds.length === 0) return;

    const watchesToCall = [];
    this.watches.forEach((watchOptions) => {
      // notify query listener if records in query result were updated
      for (const recordCacheId of updatedRecordCacheIds) {
        if (watchOptions.recordCacheIds.has(recordCacheId)) {
          watchesToCall.push(watchOptions);
          return;
        }
      }
    });
    watchesToCall.forEach(({ recordCacheIds, callback, formatResult }) => {
      callback({
        type: 'cache:update',
      });
    });
  }
}

export default InMemoryCache;
