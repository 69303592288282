import React from 'react';

import { objInfoMap } from 'src/app-env';

import GetCachedRecordsById from './GetCachedRecordsById';
import Query from './Query';
import { findOne } from './queries/common-queries';
import { getDefaultAttributes, getIncludeWithAttributes } from './utils';

const FindById = ({ children, objectKey, recordId, include }) => {
  const attributes = getDefaultAttributes(objInfoMap[objectKey].fields);
  const includeWithAttributes = getIncludeWithAttributes(
    objInfoMap,
    objectKey,
    include,
  );
  const params = {
    options: {
      attributes,
      include: includeWithAttributes,
      where: {
        id: {
          $eq: recordId,
        },
      },
    },
  };
  return (
    <Query method={findOne(objectKey)} params={params}>
      {({ data, loading }) => {
        return (
          <GetCachedRecordsById
            objectKey={objectKey}
            ids={[recordId]}
            attributes={attributes}
            include={includeWithAttributes}
          >
            {({ data: cachedData }) => {
              return children({
                data: loading ? cachedData[0] : data,
                loading,
              });
            }}
          </GetCachedRecordsById>
        );
      }}
    </Query>
  );
};

export default FindById;
