import invariant from 'invariant';
import React from 'react';
import warning from 'warning';

import { toFixed } from './utils';

/**
 * A React hook that gives you props to convert an HTML input into a number
 * input with the following features:
 * - Receives a JavaScript number as value (or null for no number).
 * - Calls `onValueChange` with a JavaScript number. If text is not a valid
 *   number, `onValueChange` is called with `null`.
 * - If you specify the number of decimal places allowed, you cannot enter more
 *   digits.
 * - Can detect if the text is an unsafe number (a number that cannot be
 *   accurately represented by a JavaScript number).
 *
 * Accepted number format when typing or pasting
 * 1. When you type, you can only enter digits 0-9, decimal point "." and sign
 *    "-". You cannot enter "." if allowed decimal places is 0 and you cannot
 *    enter more digits than the allowed number of decimal places.
 * 2. When you select all text and paste to replace, you can paste any text and
 *    invalid characters will be stripped out. Text will be truncated if it
 *    exceeds the allowed number of decimal places.
 *    If you are not selecting all when pasting, it behaves like typing. It
 *    simply rejects text with more decimal places than allowed.
 * 3. Can have leading zeros, e.g. "0001".
 * 4. Can have trailing zeros, e.g. "1.00".
 * 5. Can start with a ".", e.g. ".12".
 * 6. Can be just ".". The value will be treated as null. We allow this because
 *    this will happen whenever you type a fractional number < 1 character by
 *    character.
 * 7. Can end with a ".", e.g. "12.". We allow this because this will happen
 *    whenever you type a fractional number character by character.
 * 8. Can be just "-". The value will be treated as null. We allow this because
 *    this will happen whenever you type a negative number character by
 *    character.
 * 9. Exponential notation (e.g. 1e4) is not accepted.
 */
export function useNumberInputProps(
  {
    decimalPlaces,
    defaultValue,
    onValueChange,
    value,
  }: {
    /**
     * Maximum number of decimal places allowed. Must be a non-negative integer.
     * `undefined` means there is no limit.
     */
    decimalPlaces?: number;
    /**
     * Must not be Infinity, -Infinity or NaN.
     */
    defaultValue?: number | null;
    onValueChange?: (value: number | null) => void;
    /**
     * Must not be Infinity, -Infinity or NaN.
     */
    value?: number | null;
  },
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | React.MutableRefObject<HTMLInputElement | null>
    | null,
) {
  invariant(
    decimalPlaces == null ||
      (Number.isInteger(decimalPlaces) && decimalPlaces >= 0),
    `"decimalPlaces" must be a non-negative integer, received ${decimalPlaces}.`,
  );

  const [prevValue, setPrevValue] = React.useState(value);
  warning(
    !(prevValue === undefined && value !== undefined),
    'A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component.',
  );
  warning(
    !(prevValue !== undefined && value === undefined),
    'A component is changing a controlled input to be uncontrolled. This is likely caused by the value changing from a defined to undefined, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component.',
  );
  if (value !== prevValue) {
    setPrevValue(value);
  }

  // Current text displayed in the input
  const [text, setText] = React.useState(() => {
    return value === null
      ? ''
      : value !== undefined
      ? toFixed(value, decimalPlaces)
      : defaultValue == null
      ? ''
      : toFixed(defaultValue, decimalPlaces);
  });

  // Current number corresponding to the current text
  const [number, setNumber] = React.useState(
    value !== undefined ? value : defaultValue,
  );

  // Update local state when `value` changes
  if (value !== undefined && value !== number) {
    setText(value == null ? '' : toFixed(value, decimalPlaces));
    setNumber(value);
  }

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  // Save caret position so that when new text is rejected, the cursor does
  // not jump to the end of the input.
  const { caretStart, caretEnd, inputProps } = useCaretPosition();
  // Keep track of focus state so we can display some message if number is NaN
  // or +-Infinity.
  const [isFocused, setIsFocused] = React.useState(false);
  const displayedText = isFocused
    ? text
    : Number.isNaN(number)
    ? 'Invalid number'
    : number === Infinity
    ? 'Infinity'
    : number === -Infinity
    ? '-Infinity'
    : text;

  const handleBlur = () => {
    setIsFocused(false);
    setText(number == null ? '' : toFixed(number, decimalPlaces));
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  // Set to true if user has selected all text in the input and pasting
  const isPasteAllRef = React.useRef(false);
  const handlePaste = () => {
    if (
      inputRef.current &&
      inputRef.current.selectionStart != null &&
      inputRef.current.selectionEnd != null &&
      inputRef.current.selectionEnd - inputRef.current.selectionStart ===
        text.length
    ) {
      isPasteAllRef.current = true;
    }
  };

  const handleValueChange = (newText: string) => {
    // Save and reset `isPasteAllRef`
    const isPasteAll = isPasteAllRef.current;
    isPasteAllRef.current = false;

    const scheduleUpdateCaret = () => {
      window.requestAnimationFrame(() => {
        inputRef.current?.setSelectionRange(caretStart, caretEnd);
      });
    };

    // Remove characters other than 0-9, ".", "-"
    const newTextWithoutInvalidChars = newText.replace(/[^0-9.-]/g, '');
    if (newTextWithoutInvalidChars === text) {
      // Same text as before, no need to update
      scheduleUpdateCaret();
      return;
    }
    if (
      !isAcceptedFormat(newTextWithoutInvalidChars, isPasteAll, decimalPlaces)
    ) {
      // Rejects invalid format
      scheduleUpdateCaret();
      return;
    }

    if (
      !newTextWithoutInvalidChars ||
      !Number.isFinite(Number(newTextWithoutInvalidChars))
    ) {
      // Has accepted format but not a valid number. Must be "" or "-" or ".".
      setText(newTextWithoutInvalidChars);
      if (number !== null) {
        setNumber(null);
        onValueChange?.(null);
      }
      return;
    }

    const truncatedText = truncate(newTextWithoutInvalidChars, decimalPlaces);
    setText(truncatedText);
    const truncatedNumber = Number(truncatedText);
    if (truncatedNumber !== number) {
      setNumber(truncatedNumber);
      onValueChange?.(truncatedNumber);
    }
  };

  return {
    ...inputProps,
    defaultValue: undefined,
    number,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onPaste: handlePaste,
    onValueChange: handleValueChange,
    ref: (instance: HTMLInputElement | null) => {
      inputRef.current = instance;
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref) {
        ref.current = instance;
      }
    },
    value: displayedText,
  };
}

/**
 * Check if a string has the format of a number. This function is not very
 * strict. It accepts text like "12." even if allowed decimal places is 0
 * because we don't want to simply reject it. We rather want to use `truncate`
 * to keep the integer part.
 */
export function isAcceptedFormat(
  str: string,
  isPasteAll: boolean,
  decimalPlaces?: number,
) {
  const fractionPattern =
    isPasteAll || decimalPlaces == null
      ? // allow any number of digits after the decimal point
        String.raw`\.?\d*`
      : decimalPlaces <= 0
      ? // no decimal point
        ''
      : // allow up to `decimalPlaces` number of digits after the decimal point
        String.raw`\.?\d{0,${decimalPlaces}}`;
  const regex = new RegExp(String.raw`^-?\d*${fractionPattern}$`);
  return regex.test(str);
}

/**
 * Truncates the number if it exceeds the maximum decimal places.
 */
export function truncate(
  /**
   * Number to truncate. Must be a proper number.
   */
  num: string,
  maxDecimalPlaces?: number,
) {
  if (
    maxDecimalPlaces == null ||
    maxDecimalPlaces < 0 ||
    !Number.isInteger(maxDecimalPlaces)
  ) {
    return num;
  }
  if (maxDecimalPlaces === 0) {
    return num.replace(/\.\d*$/, '');
  }
  const [integer, decimalPoint = '', fraction = ''] = num.split(/(\.)/);
  return `${integer}${decimalPoint}${fraction.slice(0, maxDecimalPlaces)}`;
}

function useCaretPosition() {
  const [caretStart, setCaretStart] = React.useState(0);
  const [caretEnd, setCaretEnd] = React.useState(0);

  const saveCaretPosition = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { selectionStart, selectionEnd } = event.currentTarget;
    setCaretStart(selectionStart!);
    setCaretEnd(selectionEnd!);
  };

  const inputProps = {
    onClick: saveCaretPosition,
    onFocus: saveCaretPosition,
    onKeyUp: saveCaretPosition,
  };

  return {
    caretStart,
    caretEnd,
    inputProps,
  };
}
