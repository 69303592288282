import { createUserDefinedObjectBaseModel } from './UserDefinedObjectBaseModel';

class ModelManager {
  constructor({ apiClient, objInfoMap }) {
    this.models = new Map();
    this.userDefinedObjectBaseModel = createUserDefinedObjectBaseModel({
      apiClient,
    });

    Object.values(objInfoMap).forEach((objInfo) =>
      objInfo.key.startsWith('_')
        ? this.addBuiltinObjectModel(objInfo)
        : this.addUserDefinedObjectModel(objInfo),
    );
  }

  addBuiltinObjectModel(objInfo) {
    const model = {
      [objInfo.key]: class {},
    }[objInfo.key];
    Object.defineProperties(model, {
      definition: {
        get: () => ({
          key: objInfo.key,
          pluralName: objInfo.pluralName,
          singularName: objInfo.singularName,
        }),
        configurable: false,
      },
      fields: {
        value: objInfo.fields,
        writable: false,
        configurable: false,
      },
      relationships: {
        value: objInfo.relationships,
        writable: false,
        configurable: false,
      },
    });
    this.models.set(objInfo.key, model);
  }

  addUserDefinedObjectModel(objInfo) {
    const model = {
      [objInfo.key]: class extends this.userDefinedObjectBaseModel {},
    }[objInfo.key];
    Object.defineProperties(model, {
      definition: {
        get: () => ({
          key: objInfo.key,
          pluralName: objInfo.pluralName,
          singularName: objInfo.singularName,
        }),
        configurable: false,
      },
      fields: {
        value: objInfo.fields,
        writable: false,
        configurable: false,
      },
      relationships: {
        value: objInfo.relationships,
        writable: false,
        configurable: false,
      },
    });
    this.models.set(objInfo.key, model);
  }

  getUserDefinedObjectModelMap() {
    if (this.userDefinedModels) {
      return this.userDefinedModels;
    }
    this.userDefinedModels = Object.create(null);
    this.models.forEach((model) => {
      if (!model.definition.key.startsWith('_')) {
        this.userDefinedModels[model.definition.key] = model;
      }
    });
    return this.userDefinedModels;
  }
}

export default ModelManager;
