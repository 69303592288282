import queryString from 'query-string';

import {
  Form,
  SubmitButton,
  TextField,
} from 'src/components/bappo-components/src';

import {
  emailValidators,
  passwordValidators,
  nameValidators,
} from './validators';

type Values = {
  email: string;
  password: string;
};
export interface LoginFormProps {
  initialValues?: Partial<Values>;
  onEmailChange?: (email: string) => any;
  onSubmit?: (values: Values) => any;
  testID?: string;
}

const LoginForm = ({
  initialValues,
  onEmailChange,
  onSubmit,
  testID = 'login-form',
}: LoginFormProps) => {
  const urlParams = queryString.parse(window.location.search);
  const completesignup = urlParams && urlParams.completesignup;

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} testID={testID}>
      <TextField
        name="email"
        label="Username"
        labelStyle={{ color: 'white' }}
        testID="username-field"
        validate={emailValidators}
        autoFocus={true}
        onValueChange={onEmailChange}
        placeholder="Your e-mail address"
        type="text"
      />
      <TextField
        name="password"
        label="Password"
        labelStyle={{ color: 'white' }}
        testID="password-field"
        validate={passwordValidators}
        placeholder="Password"
        type="password"
      />
      {completesignup && (
        <>
          <TextField
            name="newPassword"
            label="Set a New Password"
            labelStyle={{ color: 'white' }}
            validate={passwordValidators}
            placeholder="New Password"
            type="password"
            testID="password-field"
          />
          <TextField
            name="given_name"
            label="First Name"
            labelStyle={{ color: 'white' }}
            placeholder="First Name"
            validate={nameValidators}
          />
          <TextField
            name="family_name"
            label="Last Name"
            labelStyle={{ color: 'white' }}
            placeholder="Last Name"
            validate={nameValidators}
          />
        </>
      )}
      <SubmitButton style={{ height: 48 }} text="Log In" />
    </Form>
  );
};

export default LoginForm;
