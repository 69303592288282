import stringify from 'json-stable-stringify';
import _ from 'lodash';
import React from 'react';
import usePromise from 'react-use/lib/usePromise';

import { ApiClientContext } from './ApiClient';

function getInitialState(method) {
  return {
    cacheVersion: 0,
    error: undefined,
    loading: !!method,
    normalizedResult: undefined,
  };
}

function queryStateReducer(state, action) {
  switch (action.type) {
    case 'INITIALIZE':
      return getInitialState(action.method);
    case 'CACHE_UPDATED': {
      return {
        ...state,
        cacheVersion: state.cacheVersion + 1,
      };
    }
    case 'FETCH_FAILED':
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case 'FETCH_STARTED':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_SUCCEEDED': {
      const { normalizedResult } = action.isFetchMore
        ? action.apiClient.mergeAndNormalizeResult(
            action.query,
            state.normalizedResult,
            action.result,
          )
        : action.apiClient.normalizeResult(action.result);

      // optimization: keep normalizedResult if it did not change
      if (normalizedResult === state.normalizedResult) {
        return {
          ...state,
          error: undefined,
          loading: false,
        };
      }

      return {
        ...state,
        cacheVersion: state.cacheVersion + 1,
        error: undefined,
        loading: false,
        normalizedResult,
      };
    }
    case 'RESULT_UPDATED': {
      const prevResult = action.apiClient.denormalizeResult(
        action.query,
        state.normalizedResult,
      );
      const updatedResult = action.updater(prevResult, action.query);
      const { normalizedResult } = action.apiClient.normalizeResult(
        updatedResult,
      );
      return {
        ...state,
        cacheVersion: state.cacheVersion + 1,
        normalizedResult,
      };
    }
    default:
      throw new Error();
  }
}

export function useQuery({ method, params }) {
  let paramsJson;
  try {
    paramsJson = stringify(params);
  } catch (err) {
    throw new Error(`params must be serializable`);
  }

  const settleIfMounted = usePromise();

  const apiClient = React.useContext(ApiClientContext);

  const [state, dispatch] = React.useReducer(
    queryStateReducer,
    getInitialState(method),
  );

  // refs to get latest values
  const apiClientRef = React.useRef();
  const methodRef = React.useRef();
  const paramsRef = React.useRef();
  React.useEffect(() => {
    apiClientRef.current = apiClient;
    methodRef.current = method;
    paramsRef.current = params;
  });

  const fetchQuery = React.useCallback(
    async (query, isFetchMore = false) => {
      if (!query.method) return;

      dispatch({
        type: 'FETCH_STARTED',
      });

      try {
        const result = await settleIfMounted(apiClient.query(query));
        dispatch({
          type: 'FETCH_SUCCEEDED',
          apiClient,
          query,
          isFetchMore,
          result,
        });
      } catch (err) {
        if (err.data) {
          const error = {
            message: err.message,
            data: err.data,
          };
          dispatch({
            type: 'FETCH_FAILED',
            error,
          });
          return;
        }
        throw err;
      }
    },
    [apiClient, settleIfMounted],
  );

  // initiate a request and subscribe to cached records
  React.useEffect(
    () => {
      dispatch({
        type: 'INITIALIZE',
        method,
      });

      // fetch for the first time
      fetchQuery({
        method,
        params,
      });
    },
    // using paramsJson instead of params as we want to deep compare
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiClient, fetchQuery, method, paramsJson],
  );

  // watch cached record updates
  React.useEffect(
    () => {
      // subscribe to cache
      const unwatch = apiClient.watchResult({
        normalizedResult: state.normalizedResult,
        callback: ({ type, payload }) => {
          switch (type) {
            case 'cache:update':
              dispatch({
                type: 'CACHE_UPDATED',
              });
              break;
            case 'cache:invalidate':
              // refetch query to keep the query result up-to-date
              // delay refetch in case unsubscription will occur very soon
              requestAnimationFrame(() => {
                if (
                  apiClientRef.current === apiClient &&
                  methodRef.current === method &&
                  _.isEqual(paramsRef.current, params)
                ) {
                  fetchQuery({
                    method,
                    params,
                  });
                }
              });
              break;
            default:
              throw new Error();
          }
        },
      });

      return () => {
        unwatch();
      };
    },
    // using paramsJson instead of params as we want to deep compare
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiClient, fetchQuery, method, paramsJson, state.normalizedResult],
  );

  const data = React.useMemo(
    () =>
      method &&
      apiClient.denormalizeResult({ method, params }, state.normalizedResult),
    // using paramsJson instead of params as we want to deep compare
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiClient, state.cacheVersion, method, paramsJson, state.normalizedResult],
  );
  const fetchMore = React.useCallback(
    async (fetchMoreParams = {}) => {
      await fetchQuery(
        {
          method,
          params: {
            ...(params || {}),
            ...fetchMoreParams,
          },
        },
        true,
      );
    },
    // using paramsJson instead of params as we want to deep compare
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchQuery, method, paramsJson],
  );
  const updateResult = React.useCallback(
    (updater) => {
      dispatch({
        type: 'RESULT_UPDATED',
        apiClient,
        query: {
          method,
          params,
        },
        updater,
      });
    },
    // using paramsJson instead of params as we want to deep compare
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiClient, method, paramsJson],
  );

  return {
    data,
    error: state.error,
    loading: state.loading,
    fetchMore,
    updateResult,
  };
}

export default function Query({ children, method, params }) {
  const queryResult = useQuery({
    method,
    params,
  });
  return children(queryResult);
}
