import { css } from 'styled-components';

const sizes: {
  [size: string]: number;
} = {
  giant: 1170,
  desktop: 992,
  tablet: 768,
  phone: 376,
};

// iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce(
  (accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = sizes[label] / 16;
    return {
      ...accumulator,
      [label]: (first: any, ...args: any[]) => css`
        @media (max-width: ${emSize}em) {
          ${css(first, ...args)};
        }
      `,
    };
  },
  {} as {
    [size: string]: typeof css;
  },
);

export default media;
