const colors = {
  primaryColor: '#ff7f09',
  gray98: '#f8f9fd',
  gray96: '#f2f4f7',
  gray94: '#e9ecf0',
  gray88: '#d7dce0',
  gray80: '#c0c6cc',
  gray70: '#a4acb3',
  gray50: '#6b7986',
  gray30: '#3c444d',
  gray20: '#272d33',
  gray10: '#14171a',
  backgroundColor: '#f8f8f8',
  white: '#ffffff',
};

export const theme = {
  navlab: {
    ...colors,
    rowBorderColor: colors.gray94,
    rowBackgroundColor: colors.white,
  },
  userDefined: {
    primaryColor: '#FF7F09',
    secondaryColor: '#09CEFF',
    secondaryColorLight: 'rgba(9, 206, 255, 0.08)',
  },
};
