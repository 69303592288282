import styled from 'styled-components';

import { ActivityIndicator } from 'src/components/bappo-components/src';

const Spinner = ({ className, ...props }) => {
  return (
    <div id="plain-spinner" className={className}>
      <ActivityIndicator {...props} />
    </div>
  );
};

export default styled(Spinner)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
