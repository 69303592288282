import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import NavlabLogo from 'src/components/NavlabLogo';
import { Form } from 'src/components/bappo-components/src';

import ResetPasswordForm from './ResetPasswordForm';
import {
  Container,
  ImageContainer,
  LeftContainer,
  PrimaryText,
  TextContainer,
} from './StyledComponents';

import { Alert } from 'src/components/bappo-components/src';

const ResetPasswordPage = () => {
  const history = useHistory();
  const [email, setEmail] = React.useState('');

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    const username = urlParams && urlParams.username;

    if(username) {
      setEmail(username);
    }  
  },[]);

  const handleSubmit = async (values) => {
    const { verificationCode, newPassword } = values;

    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword)

      history.push('/login');
    } catch (err) {
      // TODO: handle err
      switch (err.code) {
        case 'UserNotFoundException':
          throw new Form.SubmissionError({ email: err.message });
        case 'NotAuthorizedException':
          throw new Form.SubmissionError({ email: err.message });
        case 'CodeMismatchException':
          throw new Form.SubmissionError({ verificationCode: err.message });
        default:
          throw err;
      }
    }
  };

  const handleResendVerificationCode = async () => {
    try {
      await Auth.forgotPassword(email);
      Alert.alert("A verification code has been sent to your email address.");
    } catch (err) {
      // TODO: handle err
      switch (err.code) {
        case 'UserNotFoundException':
          throw new Form.SubmissionError({ verificationCode: err.message });
        case 'NotAuthorizedException':
          throw new Form.SubmissionError({ verificationCode: err.message });
        default:
          throw err;
      }
    }
  };
  
  return (
    <Container>
      <LeftContainer>
        <NavlabLogo />
        <ResetPasswordForm initialValues={{ email }} onEmailChange={setEmail} onSubmit={handleSubmit} onResendVerificationCode={handleResendVerificationCode} />
      </LeftContainer>
      <ImageContainer>
        <TextContainer>
          <PrimaryText>Freight quoting revolutionised.</PrimaryText>
        </TextContainer>
      </ImageContainer>
    </Container>
  );
};

export default ResetPasswordPage;
