export const bulkCreate = objectKey => ({
  name: `${objectKey}:bulkCreate`,
  buildQuery: (params = {}) => {
    return {
      method: 'bulkCreate',
      params: {
        ...params,
        objectKey,
      },
    };
  },
});

export const bulkUpdate = objectKey => ({
  name: `${objectKey}:bulkUpdate`,
  buildQuery: (params = {}) => {
    return {
      method: 'bulkUpdate',
      params: {
        ...params,
        objectKey,
      },
    };
  },
});

export const create = objectKey => ({
  name: `${objectKey}:create`,
  buildQuery: (params = {}) => {
    return {
      method: 'create',
      params: {
        ...params,
        objectKey,
      },
    };
  },
});

export const destroy = objectKey => ({
  name: `${objectKey}:destroy`,
  buildQuery: (params = {}) => {
    return {
      method: 'destroy',
      params: {
        ...params,
        objectKey,
      },
    };
  },
  invalidate: {
    shouldInvalidateRecord: id => id.startsWith(`${objectKey}:`),
  },
});

export const forceDestroy = objectKey => ({
  name: `${objectKey}:forceDestroy`,
  buildQuery: (params = {}) => {
    return {
      method: 'forceDestroy',
      params: {
        ...params,
        objectKey,
      },
    };
  },
  invalidate: {
    shouldInvalidateRecord: id => id.startsWith(`${objectKey}:`),
  },
});

export const tagAll = objectKey => ({
  name: `${objectKey}:tagAll`,
  buildQuery: (params = {}) => {
    return {
      method: 'tagAll',
      params: {
        ...params,
        objectKey,
      },
    };
  },
  invalidate: {
    shouldInvalidateRecord: id => id.startsWith(`${objectKey}:`),
  },
});

export const untagAll = objectKey => ({
  name: `${objectKey}:untagAll`,
  buildQuery: (params = {}) => {
    return {
      method: 'untagAll',
      params: {
        ...params,
        objectKey,
      },
    };
  },
  invalidate: {
    shouldInvalidateRecord: id => id.startsWith(`${objectKey}:`),
  },
});

export const update = objectKey => ({
  name: `${objectKey}:update`,
  buildQuery: (params = {}) => {
    return {
      method: 'update',
      params: {
        ...params,
        objectKey,
      },
    };
  },
});

export const updateOne = objectKey => ({
  name: `${objectKey}:updateOne`,
  buildQuery: (params = {}) => {
    return {
      method: 'updateOne',
      params: {
        ...params,
        objectKey,
      },
    };
  },
});
