import React from 'react';

import { TextInput } from 'src/components/bappo-components/src';
import { TextInputProps } from 'src/components/bappo-components/src/primitives/TextInput/types';
import { EmailField, ObjectFieldTypes } from 'src/types/object';

import { createField } from './createField';

export type EmailInputProps = Omit<TextInputProps, 'multiline' | 'type'> & {
  properties: EmailField['properties'];
};

export const EmailInput = React.forwardRef(function EmailInput(
  { properties, value, ...rest }: EmailInputProps,
  ref: React.Ref<TextInput>,
) {
  // type="email" is removed for now because of React DOM bug
  // https://github.com/facebook/react/issues/6368
  return (
    <TextInput {...rest} multiline={false} value={value || ''} ref={ref} />
  );
});

export const EmailInputField = createField(ObjectFieldTypes.EMAIL, EmailInput);
