import { ObjectField, ObjectFieldTypes, ObjectInfoMap } from 'src/types/object';
import { isNonNullable } from 'src/types/utils';

import { OptionInclude } from './types';

export const getDefaultAttributes = (fields: ObjectField[]) => {
  return fields.map((field) =>
    field.type === ObjectFieldTypes.REFERENCE ? `${field.name}_id` : field.name,
  );
};

export const getIncludeWithAttributes = (
  objInfoMap: ObjectInfoMap,
  objectKey: string,
  include?: OptionInclude,
): typeof include => {
  if (!include) return undefined;

  const objInfo = objInfoMap[objectKey];
  return include
    .map(
      ({ as, attributes: childAttributes, include: childInclude, ...rest }) => {
        const relationship = objInfo.relationships.find(
          (rel) => rel.targetName === as,
        );
        if (!relationship) return null;
        return {
          ...rest,
          as,
          attributes:
            childAttributes ||
            getDefaultAttributes(
              objInfoMap[relationship.targetObjectKey].fields,
            ),
          include: getIncludeWithAttributes(
            objInfoMap,
            relationship.targetObjectKey,
            childInclude,
          ),
        };
      },
    )
    .filter(isNonNullable);
};
