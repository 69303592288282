import React from 'react';

import { Select } from 'src/components/bappo-components/src';
import { SelectProps } from 'src/components/bappo-components/src/components/OldSelect/types';
import { FixedListField, ObjectFieldTypes } from 'src/types/object';

import { createField } from './createField';

export type FixedListInputProps = Pick<
  SelectProps,
  | 'accessibilityLabel'
  | 'autoFocus'
  | 'clearable'
  | 'onBlur'
  | 'onFocus'
  | 'onValueChange'
  | 'placeholder'
  | 'readOnly'
  | 'style'
  | 'testID'
  | 'value'
> & {
  properties: FixedListField['properties'];
};

export const FixedListInput = React.forwardRef(function FixedListInput(
  { properties, ...rest }: FixedListInputProps,
  ref: React.Ref<{
    blur: () => void;
    focus: () => void;
  }>,
) {
  const options = properties.options
    .slice()
    .sort((a, b) => a.pos - b.pos)
    .map((option) => ({
      label: option.label,
      value: option.id,
    }));

  return <Select {...rest} options={options} ref={ref} />;
});

export const FixedListInputField = createField(
  ObjectFieldTypes.FIXED_LIST,
  FixedListInput,
);
