import React from 'react';
import { useHistory } from 'react-router';

import { useLogout } from 'src/apis/AuthenticatedRequester';
import {
  View,
  Icon,
  TouchableView,
  Text,
  styled,
  Menu,
} from 'src/components/bappo-components/src';
import media from 'src/style/media';

const PageHeaderContext = React.createContext<
  ((title: string) => void) | undefined
>(undefined);

export const HEADER_HEIGHT = 64;

const Header = ({ pageTitle }: { pageTitle: string }) => {
  return <TitleText numberOfLines={1}>{pageTitle}</TitleText>;
};

const Page = ({ children }: { children?: React.ReactNode }) => {
  const [pageTitle, setPageTitle] = React.useState('Navlab');
  const history = useHistory();
  const logout = useLogout();
  
  return (
    <PageContainer>
      <PageHeaderContext.Provider value={setPageTitle}>
        <Container>
          <LeftContainer onPress={() => history.goBack()}>
            <BackIcon />
            <BackTitleText>Back</BackTitleText>
          </LeftContainer>
          <TitleContainer>
            <Header pageTitle={pageTitle} />
          </TitleContainer>
          <RightContainer>
            <Menu icon="person">
              <Menu.Item icon="person-outline" onPress={() => history.push('/user-settings')}>
                User Settings
              </Menu.Item>
              <Menu.Item icon="monetization-on" onPress={() => history.push('/billing')}>
                Billing
              </Menu.Item>
              <Menu.Item icon="arrow-back" onPress={logout}>
                Logout
              </Menu.Item>
            </Menu>
          </RightContainer>
        </Container>
        {children}
      </PageHeaderContext.Provider>
    </PageContainer>
  );
};

export default Page;

export function useSetPageTitle() {
  const setPageTitle = React.useContext(PageHeaderContext)!;
  return setPageTitle;
}

export const Container = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dddbda;
  height: ${HEADER_HEIGHT}px;
  align-items: center;

  ${media.tablet`
    height: 52px;
  `};
`;

const LeftContainer = styled(TouchableView)`
  width: 70px;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = styled(View)`
  width: 40px;
  flex-direction: row;
`;

const TitleContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  flex; 1;
`;

const BackIcon = styled(Icon).attrs((props) => ({
  name: 'chevron-left',
}))`
  margin-right: 0px;
`;

const BackTitleText = styled(Text)`
  font-size: 14px;
  padding-right: 10px;
`;

const TitleText = styled(Text)`
  font-size: 16px;
`;

const PageContainer = styled(View)`
  flex: 1;
  flex-direction: column;
  display: flex;
`;
