import { Auth } from 'aws-amplify';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import NavlabLogo from 'src/components/NavlabLogo';
import { Form } from 'src/components/bappo-components/src';

import LoginForm from './LoginForm';
import {
  Container,
  ForgotPasswordLink,
  ImageContainer,
  LeftContainer,
  LinkGroup,
  PrimaryText,
  TextContainer,
} from './StyledComponents';

import queryString from 'query-string';

import * as Sentry from '@sentry/react';

const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = React.useState('');

  let forgotPasswordLink = '/account/forgot-password';
  if (email) {
    forgotPasswordLink = `${forgotPasswordLink}?${queryString.stringify({username: email})}`;
  }

  const handleSubmit = async (values) => {
    const { email, password, newPassword, given_name, family_name } = values;

    const sanitized_email = email?.trim();
    const sanitized_password = password?.trim();
    const sanitized_newPassword = newPassword?.trim();
    const sanitized_given_name = given_name?.trim();
    const sanitized_family_name = family_name?.trim();

    try {
      const user = await Auth.signIn(sanitized_email, sanitized_password);

      try {
        Sentry.setContext("user", {
          given_name: user?.attributes?.given_name,
          family_name: user?.attributes?.family_name,
          email: user?.attributes?.email,
          tenant: user?.attributes ? user?.attributes["custom:_tenant"] : '',
        });
      } catch(err) {
        console.log(err);
      }

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Require the newly invited user to set password
        await Auth.completeNewPassword(user, sanitized_newPassword, {
          given_name: sanitized_given_name,
          family_name: sanitized_family_name,
        });
        // at this time the user is logged in if no MFA required
      }

      let { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    } catch (err) {
      // TODO: handle err
      switch (err.code) {
        case 'UserNotFoundException':
          throw new Form.SubmissionError({ email: err.message });
        case 'NotAuthorizedException':
          throw new Form.SubmissionError({ email: err.message });
        default:
          throw err;
      }
    }
  };

  return (
    <Container>
      <LeftContainer>
        <NavlabLogo />
        <LoginForm onEmailChange={setEmail} onSubmit={handleSubmit} />
        <LinkGroup>
          <ForgotPasswordLink to={forgotPasswordLink}>
            Forgot Password?
          </ForgotPasswordLink>
        </LinkGroup>
      </LeftContainer>
      <ImageContainer>
        <TextContainer>
          <PrimaryText>Freight quoting revolutionised.</PrimaryText>
        </TextContainer>
      </ImageContainer>
    </Container>
  );
};

export default LoginPage;
