import React from 'react';
import warning from 'warning';
import {
    CheckboxField,
    styled,
    Text,
    View,
  } from 'src/components/bappo-components/src';
  import { InputFieldWrapper } from '../wrappers';
  import { InputField, InputFieldProps } from '../../../primitives/Form/types';
  import { useFieldState } from '../../../primitives/Form';

  interface WeekdayPickerProps{
    weekdays?: string[]
  }
type Props = Omit<InputFieldProps<string>, 'value'> & WeekdayPickerProps;

const WeekdayPicker = (
  props: Props,
  ref: React.Ref<InputField>,
  
  )=> {
    const {
      fieldState: passedFieldState,
      label,
      required,
      reserveErrorSpace,
      testID,
      validate,
      weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      ...rest
    } = props;

    
    warning(
      !(validate && !reserveErrorSpace),
      `Prop "reserveErrorSpace" is set to false while "validate" is supplied. You will not see the validation error.`,
    );
  

    //For setting up onBlur and focus, work on the below
    // // const focusInput = React.useCallback(
    // //   () => inputRef.current && inputRef.current.focus(),
    // //   [],
    // // );
    // // const blurInput = React.useCallback(
    // //   () => inputRef.current && inputRef.current.blur(),
    // //   [],
    // // );
    // // React.useImperativeHandle(ref, () => ({
    // //   focus: focusInput,
    // //   blur: blurInput,
    // // }));
  
    const { fieldState, onBlur, onFocus, onValueChange } = useFieldState(props);
    return (
      <InputFieldWrapper
      fieldState={fieldState}
      ////focusInput={focusInput}
      label={label}
      onValueChange={onValueChange}
      required={required}
      reserveErrorSpace={reserveErrorSpace}
      testID={testID}
    >
      <MainContainer style={{maxWidth: "300px"}}>
        <WeekdayContainer>
          {weekdays.map((day:string)=>{
            return renderWeekdayPicker(day)
          })
          }
        </WeekdayContainer>
      </MainContainer>
    </InputFieldWrapper>
    )
  };
  
  const MainContainer = styled(View)`
    margin-top: 8px;
    justify-content: space-between;
    flex-grow: 1;
  `;
  const WeekdayContainer = styled(MainContainer)`
    flex-direction: row;
  `;
  
  const PickerContainer = styled(MainContainer)`
    align-items: center;
  `;
  
  const renderWeekdayPicker = (
    day: string) => {
      return (
        <PickerContainer>
          <Text>{day}</Text>
          <CheckboxField name={`checkbox-${day}`} label="" testID="checkbox1" />
        </PickerContainer>
      );
    };

export default React.forwardRef(WeekdayPicker);