import React from 'react';

import { objInfoMap } from 'src/app-env';
import {
  InputField,
  InputFieldProps,
} from 'src/components/bappo-components/src/primitives/Form/types';
import { ObjectFieldTypes } from 'src/types/object';
import { impossible } from 'src/utils/TypeUtils';
import { required as requiredValidator } from 'src/utils/ValidationUtils';

import { AutoIncrementInputField } from './AutoIncrement';
import { CurrencyInputField } from './Currency';
import { DateInputField } from './Date';
import { EmailInputField } from './Email';
import { FixedListInputField } from './FixedList';
import { MonthInputField } from './Month';
import { PhoneNumberInputField } from './PhoneNumber';
import { QuantityInputField } from './Quantity';
import { ReferenceInputField } from './Reference';
import { SwitchInputField } from './Switch';
import { TextInputField } from './Text';
import { TextAreaInputField } from './TextArea';
import { TimeInputField } from './Time';
import { YearInputField } from './Year';

type DatabaseFieldProps = Omit<InputFieldProps<any>, 'reserveErrorSpace'> & {
  objectKey: string;
  fieldName: string;
  style?: { [style: string]: any };
  autoFocus?: boolean;
  notShowUnit?: boolean;
  readOnly?: boolean;
};

function DatabaseField(props: DatabaseFieldProps, ref: React.Ref<InputField>) {
  const {
    objectKey,
    fieldName,
    validate: extraValidators,
    notShowUnit,
    ...rest
  } = props;

  // validate props
  const objInfo = objInfoMap[objectKey];
  if (!objInfo) throw new Error(`Object "${objectKey}" not found`);
  const fieldDefinition = objInfo.fieldByNameMap[fieldName];
  if (!fieldDefinition) {
    throw new Error(`Field "${fieldName}" not found on object "${objectKey}"`);
  }

  const label = rest.label ?? fieldDefinition.displayName;

  // add required validation
  const validate = React.useMemo(() => {
    return rest.required
      ? [
          requiredValidator({
            msg: `${label} is required`,
          }),
          ...(Array.isArray(extraValidators)
            ? extraValidators
            : extraValidators
            ? [extraValidators]
            : []),
        ]
      : extraValidators;
  }, [extraValidators, label, rest.required]);
  const commonProps = {
    ...rest,
    label,
    ref,
    validate,
  };

  switch (fieldDefinition.type) {
    case ObjectFieldTypes.AUTOINCREMENT:
      return (
        <AutoIncrementInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.CHECKBOX:
      return (
        <SwitchInputField
          {...commonProps}
          properties={fieldDefinition.properties}
          // custom validations cannot be defined on checkbox field
          validate={extraValidators}
        />
      );
    case ObjectFieldTypes.CURRENCY:
      return (
        <CurrencyInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.DATE:
      return (
        <DateInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.EMAIL:
      return (
        <EmailInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.FIXED_LIST:
      return (
        <FixedListInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.MONTH:
      return (
        <MonthInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.PHONENUMBER:
      return (
        <PhoneNumberInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.QUANTITY:
      return (
        <QuantityInputField
          {...commonProps}
          properties={{ ...fieldDefinition.properties, notShowUnit }}
        />
      );
    case ObjectFieldTypes.REFERENCE:
      return (
        <ReferenceInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.TEXT:
      return (
        <TextInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.TEXTAREA:
      return (
        <TextAreaInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.TIME:
      return (
        <TimeInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    case ObjectFieldTypes.YEAR:
      return (
        <YearInputField
          {...commonProps}
          properties={fieldDefinition.properties}
        />
      );
    default:
      return impossible(fieldDefinition);
  }
}

export default React.forwardRef(DatabaseField);
