import React from 'react';

import {
  InputFieldWrapper,
  useFieldState,
} from 'src/components/bappo-components/src';
import {
  InputField,
  InputFieldComponent,
  InputFieldProps,
} from 'src/components/bappo-components/src/primitives/Form/types';
import { ObjectField, ObjectFieldTypes } from 'src/types/object';
import { isNonNullable } from 'src/utils/TypeUtils';
import { createTypeValidator } from 'src/utils/ValidationUtils';

import { AutoIncrementInputProps } from './AutoIncrement';
import { CurrencyInputProps } from './Currency';
import { DateInputProps } from './Date';
import { EmailInputProps } from './Email';
import { FixedListInputProps } from './FixedList';
import { MonthInputProps } from './Month';
import { PhoneNumberInputProps } from './PhoneNumber';
import { QuantityInputProps } from './Quantity';
import { ReferenceInputProps } from './Reference';
import { TextInputProps } from './Text';
import { TextAreaInputProps } from './TextArea';
import { TimeInputProps } from './Time';
import { YearInputProps } from './Year';

export function createField(
  fieldType: ObjectFieldTypes.AUTOINCREMENT,
  InputComponent: React.ComponentType<AutoIncrementInputProps>,
): InputFieldComponent<string | null, AutoIncrementInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.CURRENCY,
  InputComponent: React.ComponentType<CurrencyInputProps>,
): InputFieldComponent<string | null, CurrencyInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.DATE,
  InputComponent: React.ComponentType<DateInputProps>,
): InputFieldComponent<string | null, DateInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.EMAIL,
  InputComponent: React.ComponentType<EmailInputProps>,
): InputFieldComponent<string | null, EmailInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.FIXED_LIST,
  InputComponent: React.ComponentType<FixedListInputProps>,
): InputFieldComponent<string | null, FixedListInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.MONTH,
  InputComponent: React.ComponentType<MonthInputProps>,
): InputFieldComponent<string | null, MonthInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.PHONENUMBER,
  InputComponent: React.ComponentType<PhoneNumberInputProps>,
): InputFieldComponent<string | null, PhoneNumberInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.QUANTITY,
  InputComponent: React.ComponentType<QuantityInputProps>,
): InputFieldComponent<string | null, QuantityInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.REFERENCE,
  InputComponent: React.ComponentType<ReferenceInputProps>,
): InputFieldComponent<string | null, ReferenceInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.TEXT,
  InputComponent: React.ComponentType<TextInputProps>,
): InputFieldComponent<string | null, TextInputProps>;
export function createField(
  fieldType: ObjectFieldTypes.TEXTAREA,
  InputComponent: React.ComponentType<TextAreaInputProps>,
): InputFieldComponent<string | null, TextAreaInputProps>;
// export function createField(
//   fieldType: ObjectFieldTypes.TIME,
//   InputComponent: React.ComponentType<TimeInputProps>,
// ): InputFieldComponent<string | null, TimeInputProps>;
// export function createField(
//   fieldType: ObjectFieldTypes.YEAR,
//   InputComponent: React.ComponentType<YearInputProps>,
// ): InputFieldComponent<string | null, YearInputProps>;
export function createField(
  fieldType: ObjectField['type'],
  InputComponent: React.ComponentType<any>,
): any {
  return React.forwardRef(function ObjectInputField(
    props: InputFieldProps<string | null> & {
      [inputProp: string]: any;
    },
    ref: React.Ref<InputField>,
  ) {
    const {
      fieldState: passedFieldState,
      label,
      name,
      properties,
      required,
      reserveErrorSpace,
      testID,
      validate: extraValidators,
      style,
      ...rest
    } = props;

    const inputRef = React.useRef<any>(null);
    const focusInput = React.useCallback(() => inputRef.current?.focus(), []);
    const blurInput = React.useCallback(() => inputRef.current?.blur(), []);
    React.useImperativeHandle(ref, () => ({
      focus: focusInput,
      blur: blurInput,
    }));

    const validate = React.useMemo(() => {
      return [
        // include built-in validator for this field type
        createTypeValidator({
          type: fieldType,
          properties,
        } as any),
        ...(extraValidators
          ? Array.isArray(extraValidators)
            ? extraValidators
            : [extraValidators]
          : []),
      ].filter(isNonNullable);
    }, [extraValidators, properties]);
    const { fieldState, onBlur, onFocus, onValueChange } = useFieldState<
      string | null
    >({
      ...props,
      validate,
    });

    // If this field is in a Form, then the fieldState is from the Form
    // In that case, directly setting input field variable doesn't update form state
    // So value here comes from either fieldState or props
    return (
      <InputFieldWrapper
        fieldState={fieldState}
        focusInput={focusInput}
        label={label}
        required={required}
        reserveErrorSpace={true}
        testID={testID}
        style={style || {}}
      >
        <InputComponent
          {...rest}
          value={fieldState.value || rest.value}
          ref={inputRef}
          onBlur={onBlur}
          onFocus={onFocus}
          onValueChange={onValueChange}
          properties={properties}
        />
      </InputFieldWrapper>
    );
  });
}
