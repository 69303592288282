import { Text, TextInput, View, styled } from 'src/components/bappo-components/src';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

function numIsValid(text, decimalPlaces) {
  if (!text) return true;
  const fractionRegex = decimalPlaces > 0 ? `\\.\\d{0,${decimalPlaces}}` : '';
  const regex = new RegExp(`^(\\-|\\+)?(0|([1-9]\\d*))?(${fractionRegex})?$`);
  return regex.test(text);
}

class NumberInput extends Component {
  static propTypes = {
    /**
     * Maximum number of decimal places allowed.
     */
    decimalPlaces: PropTypes.number,
    inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /**
     * Callback that is called when input text changes.
     * @param {string} value - New value
     */
    onValueChange: PropTypes.func,
    /**
     * Unit of the value.
     */
    unit: PropTypes.string,
    /**
     * Input value to show.
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    decimalPlaces: 0,
  };

  render() {
    const { decimalPlaces, inputRef, unit, value, ...rest } = this.props;
    const valueStr =
      typeof value === 'string' || typeof value === 'number' ? `${value}` : '';

    return (
      <Container>
        <StyledTextInput
          {...rest}
          ref={inputRef}
          multiline={false}
          onValueChange={this._onValueChange}
          value={valueStr}
        />
        <Unit>{unit}</Unit>
      </Container>
    );
  }

  _onValueChange = newVal => {
    const { decimalPlaces, onValueChange, value } = this.props;

    if (typeof onValueChange === 'function') {
      if (numIsValid(newVal, decimalPlaces)) {
        onValueChange(newVal);
      } else {
        onValueChange(value);
      }
    }
  };
}

export default NumberInput;

const Container = styled(View)`
  align-items: center;
  flex-direction: row;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1;
`;

const Unit = styled(Text)`
  color: grey;
  font: inherit;
  margin-left: 5px;
`;
