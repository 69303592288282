import _ from 'lodash';

// Used to identify cached record objects by strict equality check
export function recordCacheIdType() {}

/**
 * Merges two arrays of records into one
 */
export function defaultMergeResult(prev = [], fetchMoreResult) {
  // return same array if fetchMoreResult is empty so that it won't trigger a
  // state update
  if (Array.isArray(fetchMoreResult) && fetchMoreResult.length === 0) {
    return prev;
  }
  // get all the ids while keeping the order of records in prev and adding new
  // ones to the back of the list
  const ids = _.uniqBy([...prev, ...fetchMoreResult], 'id').map(rec => rec.id);
  // get a map of records and prefer records in fetchMoreResult if id is the
  // same
  const records = _.keyBy([...prev, ...fetchMoreResult], 'id');
  return ids.map(id => records[id]);
}

export const getResultFromCacheAccordingToAttributesAndInclude = (
  normalizedResult,
  denormalizeRecord,
  params,
) => {
  const {
    options: { attributes, include },
  } = params;

  const denormalize = (value, childAttributes, childInclude) => {
    if (Array.isArray(value)) {
      return value.map(v => denormalize(v, childAttributes, childInclude));
    }
    if (typeof value !== 'object' || value === null) {
      return value;
    }
    if (value.type !== recordCacheIdType) {
      return _.mapValues(value, v =>
        denormalize(v, childAttributes, childInclude),
      );
    }

    // it's a record
    const cachedRecord = denormalizeRecord(value);
    if (!cachedRecord) return cachedRecord;
    // filter attributes
    const filteredRecord = _.pickBy(cachedRecord, (childValue, childKey) => {
      if (childKey.startsWith('__') || childKey === 'id') return true;
      if (childAttributes && childAttributes.includes(childKey)) return true;
      if (childInclude && childInclude.some(({ as }) => as === childKey)) {
        return true;
      }
      if (!childAttributes && !childInclude) {
        // This should only happen for polymorphic relationships like
        // conversation "record". Otherwise we should always specify
        // childAttributes and/or childInclude.
        // Only keep primitive values to avoid circular relationship
        return typeof childValue !== 'object' || childValue === null;
      }
      return false;
    });
    // denormalize
    const denormalizedRecord = _.mapValues(
      filteredRecord,
      (childValue, childKey) => {
        const includedRelationship =
          childInclude && childInclude.find(({ as }) => as === childKey);
        if (includedRelationship) {
          return denormalize(
            childValue,
            includedRelationship.attributes,
            includedRelationship.include,
          );
        }
        return denormalize(childValue);
      },
    );
    return denormalizedRecord;
  };

  return denormalize(normalizedResult, attributes, include);
};
