import {
  Form,
  SubmitButton,
  TextField,
  View,
  Button,
} from 'src/components/bappo-components/src';

import {
  emailValidators,
  passwordValidators,
  verificationCodeValidators,
} from './validators';

type Values = {
  email: string;
  password: string;
};
export interface ResetPasswordFormProps {
  initialValues?: Partial<Values>;
  onEmailChange?: (email: string) => any;
  onSubmit?: (values: Values) => any;
  onResendVerificationCode?: () => any;
  testID?: string;
}

const ResetPasswordForm = ({
  initialValues,
  onEmailChange,
  onSubmit,
  onResendVerificationCode,
  testID = 'reset-password-form',
}: ResetPasswordFormProps) => {
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} testID={testID}>
      <TextField
        name="email"
        label="Username"
        labelStyle={{ color: 'white' }}
        testID="username-field"
        validate={emailValidators}
        autoFocus={true}
        onValueChange={onEmailChange}
        placeholder="Your e-mail address"
        type="text"
      />
      <TextField
        name="newPassword"
        label="Set a New Password"
        labelStyle={{ color: 'white' }}
        validate={passwordValidators}
        placeholder="New Password"
        type="password"
        testID="password-field"
      />
      
      <TextField
        name="verificationCode"
        label="Verification Code"
        labelStyle={{ color: 'white' }}
        validate={verificationCodeValidators}
        placeholder="Verification Code"
        type="text"
        testID="verification-code-field"
      />
      <Button text={"Send Verification Code"} onPress={onResendVerificationCode} />
      <View style={{ height: 10}} />
      <SubmitButton style={{ height: 48 }} text="Reset Password" />
    </Form>
  );
};

export default ResetPasswordForm;
