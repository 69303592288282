import React from 'react';

import { objInfoMap } from 'src/app-env';

import Query from './Query';
import { findAndCountAll } from './queries/common-queries';
import { getDefaultAttributes, getIncludeWithAttributes } from './utils';

const FindAndCountAll = ({
  children,
  objectKey,
  defaultLimit,
  include,
  where,
  order = [],
}) => {
  const params = {
    options: {
      attributes: getDefaultAttributes(objInfoMap[objectKey].fields),
      include: getIncludeWithAttributes(objInfoMap, objectKey, include),
      where,
      offset: 0,
      limit: defaultLimit,
      order,
    },
  };
  return (
    <Query method={findAndCountAll(objectKey)} params={params}>
      {({ data, loading, fetchMore, updateResult }) => {
        const queryData =
          (data && (Array.isArray(data) ? data : data.rows)) || [];
        const countAll =
          (data && (Array.isArray(data) ? 0 : data.countAll)) || 0;
        return children({
          data: queryData,
          loading,
          fetchMore: ({ offset, limit } = {}) => {
            //DO NOT fetchMore if all data items is less than defaultLimit
            const fetchLimit = limit === undefined ? defaultLimit : limit;
            if (
              queryData.length !== countAll &&
              queryData.length >= fetchLimit
            ) {
              fetchMore({
                ...params,
                options: {
                  ...params.options,
                  offset: offset === undefined ? queryData.length : offset,
                  limit: fetchLimit,
                },
              });
            }
          },
          updateResult: (newRecord) => {
            updateResult((prevResult) => {
              if (Array.isArray(prevResult)) {
                return [newRecord, ...prevResult];
              } else {
                return [newRecord, ...prevResult.rows];
              }
            });
          },
          countAll,
        });
      }}
    </Query>
  );
};

export default FindAndCountAll;
