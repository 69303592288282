import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import {
  TextInputHandle,
  TextInputProps,
} from 'src/components/bappo-components/src/primitives/TextInput/types';

import { useNumberInputProps } from './useNumberInputProps';

export type NumberInputProps = Omit<
  TextInputProps,
  'defaultValue' | 'multiline' | 'onValueChange' | 'type' | 'value'
> & {
  /**
   * Maximum number of decimal places allowed
   */
  decimalPlaces?: number;
  defaultValue?: number | null;
  onValueChange?: (value: number | null) => void;
  value?: number | null;
};

export const NumberInput = React.forwardRef(function NumberInput(
  { accessibilityLabel, onBlur, onFocus, testID, ...rest }: NumberInputProps,
  ref: React.Ref<TextInputHandle>,
) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {
    number,
    onValueChange,
    ref: combinedRef,
    ...otherNumberInputProps
  } = useNumberInputProps(rest, inputRef);

  React.useImperativeHandle(ref, () => {
    return {
      blur: () => {
        inputRef.current?.blur();
      },
      clear: () => {
        onValueChange('');
      },
      focus: () => {
        inputRef.current?.focus();
      },
    };
  });

  return (
    <StyledInput
      {..._.omit(rest, 'onValueChange')}
      {...otherNumberInputProps}
      ref={combinedRef}
      aria-label={accessibilityLabel}
      data-testid={testID}
      onBlur={() => {
        onBlur?.({
          nativeEvent: {
            value: number,
          },
        });
        otherNumberInputProps.onBlur();
      }}
      onChange={(event) => onValueChange(event.currentTarget.value)}
      onFocus={() => {
        onFocus?.({
          nativeEvent: {
            value: number,
          },
        });
        otherNumberInputProps.onFocus();
      }}
    />
  );
});

const StyledInput = styled.input`
  appearance: none;
  background-color: transparent;
  border-color: black;
  border-radius: 0;
  border-width: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  outline: none;
  resize: none;
`;
