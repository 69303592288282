import _ from 'lodash';

import objects from './objects.json';
import {
  ObjectFieldTypes,
  ObjectInfo,
  ObjectInfoMap,
  RelationshipType,
} from './types/object';

export const builtinFields = [
  {
    type: ObjectFieldTypes.DATE_TIME,
    name: '_createdAt',
    displayName: 'Created At',
    properties: {},
    required: true,
    unique: false,
  },
  {
    type: ObjectFieldTypes.DATE_TIME,
    name: '_updatedAt',
    displayName: 'Updated At',
    properties: {},
    required: true,
    unique: false,
  },
  {
    type: ObjectFieldTypes.DATE_TIME,
    name: '_deletedAt',
    displayName: 'Deleted At',
    properties: {},
    required: false,
    unique: false,
  },
  {
    type: ObjectFieldTypes.REFERENCE,
    name: '_creator',
    nameOnDBModel: '_creator',
    properties: {
      refObjectKey: 'User',
    },
    required: false,
    unique: false,
  },
  {
    type: ObjectFieldTypes.REFERENCE,
    name: '_lastModifier',
    nameOnDBModel: '_lastModifier',
    properties: {
      refObjectKey: 'User',
    },
    required: false,
    unique: false,
  },
];

export const builtinRelationships = [
  {
    type: RelationshipType.BELONGS_TO_MANY,
    targetObjectKey: '_tag',
    targetName: '_tags',
    through: {
      as: '_tagMaps',
    },
    _isBuiltIn: true,
  },
  {
    type: RelationshipType.BELONGS_TO,
    targetObjectKey: 'User',
    targetName: '_creator',
    fieldName: '_creator',
    _isBuiltIn: true,
  },
  {
    type: RelationshipType.BELONGS_TO,
    targetObjectKey: 'User',
    targetName: '_lastModifier',
    fieldName: '_lastModifier',
    _isBuiltIn: true,
  },
];

const RELATIONSHIP_DEPTH_LIMIT = 4;
const buildFieldByPathMap = (
  objInfoMap: ObjectInfoMap,
  objInfo: ObjectInfo,
  path: string,
  relationshipsSeen: Record<string, boolean> = Object.create(null),
): ObjectInfo['fieldByPathMap'] => {
  const pathPrefix = path ? `${path}.` : '';
  return {
    [`${pathPrefix}id`]: {
      name: 'id',
      displayName: 'ID',
      type: ObjectFieldTypes.TEXT,
    },
    ...objInfo.fields.reduce((dict, field) => {
      const ret: ReturnType<typeof buildFieldByPathMap> = {
        ...dict,
        [`${pathPrefix}${field.name}`]: {
          ...field,
          objKey: objInfo.key,
        },
      };
      if (field.type === ObjectFieldTypes.REFERENCE) {
        ret[`${pathPrefix}${field.name}_id`] = {
          ...field,
          objKey: objInfo.key,
        };
      }
      return ret;
    }, {}),
    ...objInfo.relationships.reduce((dict, relationship) => {
      // limit the depth of relationship path
      if (path.split('.').length >= RELATIONSHIP_DEPTH_LIMIT) return dict;

      const { type, targetName, targetObjectKey } = relationship;
      if (type !== RelationshipType.BELONGS_TO) return dict;

      // does not allow going back, e.g. invoice.invoiceItems.invoice
      const relationshipId = `${objInfo.key}:${targetName}`;
      if (relationshipsSeen[relationshipId]) return dict;

      const targetObjInfo = objInfoMap[targetObjectKey];
      return {
        ...dict,
        ...buildFieldByPathMap(
          objInfoMap,
          targetObjInfo,
          `${pathPrefix}${targetName}`,
          {
            ...relationshipsSeen,
            [relationshipId]: true,
          },
        ),
      };
    }, {}),
  };
};

export const objInfoMap: ObjectInfoMap = _.mapValues(
  objects as any,
  (objDefinition) => {
    const fields = [
      ...builtinFields,
      ...objDefinition.fields.map((field: any) => ({
        type: field.type,
        name: field.name,
        displayName: field.displayName,
        properties: field.properties,
        required: field.required,
        unique: field.unique,
      })),
    ];
    const relationships = [
      ...builtinRelationships,
      ...objDefinition.relationships.map((relationship: any) => ({
        type: relationship.type,
        targetObjectKey: relationship.targetObjectKey,
        targetName: relationship.targetName,
      })),
    ];
    const objInfo: ObjectInfo = {
      key: objDefinition.key,
      pluralName: objDefinition.name,
      singularName: objDefinition.singularName,
      isCrossTenant: objDefinition.isCrossTenant,
      fields,
      relationships,
      fieldByNameMap: {},
      // Will be populated outside the loop as it needs access to other objects
      fieldByPathMap: {},
    };
    objInfo.fieldByNameMap = objInfo.fields.reduce(
      (map: ObjectInfo['fieldByNameMap'], field) => {
        const ret = {
          ...map,
          [field.name]: {
            ...field,
            objKey: objInfo.key,
          },
        };
        if (field.type === ObjectFieldTypes.REFERENCE) {
          ret[`${field.name}_id`] = {
            ...field,
            objKey: objInfo.key,
          };
        }
        return ret;
      },
      {},
    );
    return objInfo;
  },
);
Object.values(objInfoMap).forEach((objInfo) => {
  objInfo.fieldByPathMap = buildFieldByPathMap(objInfoMap, objInfo, '');
});

Object.assign(objInfoMap, {
  _tag: {
    key: '_tag',
    pluralName: 'Tags',
    singularName: 'Tag',
    fields: [
      {
        type: ObjectFieldTypes.TEXT,
        name: 'name',
        displayName: 'Name',
        properties: {},
        required: true,
        unique: false,
      },
      {
        type: ObjectFieldTypes.DATE_TIME,
        name: 'createdAt',
        displayName: 'Created At',
        properties: {},
        required: true,
        unique: false,
      },
      {
        type: ObjectFieldTypes.DATE_TIME,
        name: 'updatedAt',
        displayName: 'Updated At',
        properties: {},
        required: true,
        unique: false,
      },
      {
        type: ObjectFieldTypes.REFERENCE,
        name: 'creator_id',
        displayName: 'Creator',
        properties: {
          refObjectKey: 'User',
        },
        required: false,
        unique: false,
      },
    ],
    relationships: [
      {
        type: RelationshipType.BELONGS_TO,
        targetObjectKey: 'User',
        targetName: 'creator',
        _isBuiltIn: true,
      },
    ],
  },
});
