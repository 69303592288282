import memoize from 'fast-memoize';
import {
  defaultMergeResult,
  getResultFromCacheAccordingToAttributesAndInclude,
} from '../helpers';

export const findAll = memoize(objectKey => ({
  name: `${objectKey}:findAll`,
  buildQuery: (params = {}) => {
    return {
      method: 'findAll',
      params: {
        ...params,
        objectKey,
      },
    };
  },
  getResultFromCache: getResultFromCacheAccordingToAttributesAndInclude,
  mergeResult: defaultMergeResult,
}));

export const findAndCountAll = memoize(objectKey => ({
  name: `${objectKey}:findAndCountAll`,
  buildQuery: (params = {}) => {
    return {
      method: 'findAndCountAll',
      params: {
        ...params,
        objectKey,
      },
    };
  },
  getResultFromCache: getResultFromCacheAccordingToAttributesAndInclude,
  mergeResult: (prev, fetchMoreResult) => {
    return {
      rows: defaultMergeResult(prev.rows, fetchMoreResult.rows),
      count: fetchMoreResult.count,
    };
  },
}));

export const findOne = memoize(objectKey => ({
  name: `${objectKey}:findOne`,
  buildQuery: (params = {}) => {
    return {
      method: 'findOne',
      params: {
        ...params,
        objectKey,
      },
    };
  },
  getResultFromCache: getResultFromCacheAccordingToAttributesAndInclude,
}));
